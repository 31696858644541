
import { Row } from "react-bootstrap";
import styled from "styled-components";

//    box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
export const ModulesWrapper = styled(Row)`
  margin: 40px auto 40px auto;
  
  ${({nonfixed})=>nonfixed?(`max-width: calc(1400px + 20px);`):(`max-width: calc(1050px + 15px );`)}
  @media only screen and (max-width: 992px) {
    &{
        max-width: calc(700px + 10px);
    }
  }
  @media only screen and (max-width: 533px) {
    &{
        max-width: calc(350px + 5px);
    }
  }
`
export const ModuleWrapper = styled.a`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  border-radius: 0.42rem;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e4e6ef;
  margin: 0 5px 20px 0px;
  max-width:350px;
  ${({nonfixed})=>nonfixed?(`width: calc(24.3% - 10px);`):(`width: calc(33.2% - 10px);`)}
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  transform: scale3d(1, 1, 1);
  @media only screen and (max-width: 992px) {
    &{
        width: calc(49.4% - 10px);
    }
  }
  @media only screen and (max-width: 533px) {
    &{
        width 96%;
    }
  }
  a {
    color: #000;
    text-decoration: none;
  }
  .bosluk {
    height: 10px;
    width: 1px;
  }
  .fitter {
    height: 100%;
  }
  & a .atitle {
    color: #000;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }
  & a .atitle:hover {
    color: #06c;
  }
  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
    line-height: 1.7;
    letter-spacing: 0;
  }
  p span {
    padding: 10px;
    font-size: 15px;
  }
  & p:not(:last-child) {
    padding-bottom: 10px;
  }

  &:hover {
    box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
    transform: scale3d(1.01, 1.01, 1.01);
  }
`;
