import React, { useState } from "react";

import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import MovingText from "react-moving-text";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper styles
import "swiper/css";
import "./Slider.css";

import sliderImage1 from "./images/bg1-min.jpg";
import sliderImage2 from "./images/bg2-min.jpg";
import sliderImage3 from "./images/bg3-min.jpg";
import sliderVideo1 from "./images/bg4.mp4";
import sliderImage5 from "./images/bg5-min.jpg";
import decisionmakingwhite from "./images/decision-makingwhite.png";
import moneywhite from "./images/moneywhite.png";
import recommendwhite from "./images/recommendwhite.png";
import variabilitywhite from "./images/variabilitywhite.png";

const LearnMoreButton = ({ to }) => {
  return (
    <div className="button-learn-more-wrapper">
      <a className="button-learn-more" href={to}>
        Learn More
      </a>
    </div>
  );
};

//https://yidaoj.github.io/react-moving-text/
//https://www.bd.com/en-ca/

const MultiMovingComponent = ({
  delay,
  children,
  duration,
  animations,
  styleclass,
  carpan,
}) => {
  return (
    <MovingComponent
      duration={duration * carpan + "ms"}
      delay={delay}
      animation={animations[0]}
    >
      <MovingComponent
        duration={duration + "ms"}
        delay={delay}
        animation={animations[1]}
        styleclassName={styleclass}
      >
        {children}
      </MovingComponent>
    </MovingComponent>
  );
};

const MovingComponent = ({
  delay,
  children,
  duration,
  animation,
  styleclass,
}) => {
  const [animatinStatus, setanimatinStatus] = useState(0);
  return (
    <MovingText
      onAnimationStart={() => {
        setanimatinStatus(1);
      }}
      type={animation}
      duration={duration}
      delay={delay}
      timing="linear"
      fillMode="none"
      direction="normal"
      iteration={1}
    >
      <div
        className={styleclass}
        xx="ss"
        style={{ opacity: `${animatinStatus}` }}
      >
        {children}
      </div>
    </MovingText>
  );
};

const SlideContent1 = () => {
  const swiperSlide = useSwiperSlide();
  return (
    <>
      {swiperSlide.isActive ? (
        <>
          <div
            className="xx2"
          >
            <video className="background-video" autoPlay loop muted>
            <source src={sliderVideo1} type="video/mp4" />
            </video>
          </div>
          <div className="bg-overlay"></div>
          <div className="slider-content">
            <div className="slider-container-wrapper">
              <div className="container">
                <MovingComponent
                  duration={"1000ms"}
                  delay={"100ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"100ms"}
                    animation={"zoomIn"}
                  >
                    <h3 className="slider-title">
                    Prepare your healthcare organization for the use of Artificial Intelligence
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                

                <MovingComponent
                  duration={"500ms"}
                  delay={"800ms"}
                  animation={"slideInFromTop"}
                >
                  <LearnMoreButton to={"/about"} />
                </MovingComponent>
                <div className="row slider-containerflexbox">
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1200ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={decisionmakingwhite} />
                      <p>augments bedside decision making</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1300ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={recommendwhite} />
                      <p>provides step-by-step recommendations</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1400ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={variabilitywhite} />
                      <p>reduces variability</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1500ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={moneywhite} />
                      <p>reduces costs</p>
                    </MultiMovingComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
const SlideContent2 = () => {
  const swiperSlide = useSwiperSlide();
  return (
    <>
      {swiperSlide.isActive ? (
        <>
          <div
            className="xx"
            style={{
              backgroundImage: `url(${sliderImage2})`,
            }}
          ></div>
          <div className="bg-overlay"></div>
          <div className="slider-content">
            <div className="slider-container-wrapper">
              <div className="container">
                <MovingComponent
                  duration={"1000ms"}
                  delay={"300ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"300ms"}
                    animation={"zoomIn"}
                  >
                    <h3 className="slider-title">
                    iVMD has developed the future of AI clinical decision support
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"1000ms"}
                  delay={"500ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"500ms"}
                    animation={"slideInFromTop"}
                  >
                    <h3 className="slider-subtitle">
                      Saving doctors time and hospitals money while improving
                      patient care
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"500ms"}
                  delay={"800ms"}
                  animation={"slideInFromTop"}
                >
                  <LearnMoreButton to={"/about"} />
                </MovingComponent>
                <div className="row slider-containerflexbox">
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1200ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={decisionmakingwhite} />
                      <p>augments bedside decision making</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1300ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={recommendwhite} />
                      <p>provides step-by-step recommendations</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1400ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={variabilitywhite} />
                      <p>reduces variability</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1500ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={moneywhite} />
                      <p>reduces costs</p>
                    </MultiMovingComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const SlideContent3 = () => {
  const swiperSlide = useSwiperSlide();
  return (
    <>
      {swiperSlide.isActive ? (
        <>
          <div
            className="xx"
            style={{
              backgroundImage: `url(${sliderImage3})`,
            }}
          ></div>
          <div className="bg-overlay"></div>
          <div className="slider-content">
            <div className="slider-container-wrapper">
              <div className="container">
                <MovingComponent
                  duration={"1000ms"}
                  delay={"300ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"300ms"}
                    animation={"zoomIn"}
                  >
                    <h3 className="slider-title">
                      Quality And Consistency In Your Rhythm
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"1000ms"}
                  delay={"500ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"500ms"}
                    animation={"slideInFromTop"}
                  >
                    <h3 className="slider-subtitle">
                    Digital Intern®️ AI communicates with nurses at the bedside fast, in real-time, and within their EHR workflow
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"500ms"}
                  delay={"800ms"}
                  animation={"slideInFromTop"}
                >
                  <LearnMoreButton to={"/solutions"} />
                </MovingComponent>
                <div className="row slider-containerflexbox">
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1200ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={decisionmakingwhite} />
                      <p>augments bedside decision making</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1300ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={recommendwhite} />
                      <p>provides step-by-step recommendations</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1400ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={variabilitywhite} />
                      <p>reduces variability</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1500ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={moneywhite} />
                      <p>reduces costs</p>
                    </MultiMovingComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const SlideContent4 = () => {
  const swiperSlide = useSwiperSlide();
  return (
    <>
      {swiperSlide.isActive ? (
        <>
          <div
            className="xx"
            style={{
              backgroundImage: `url(${sliderImage1})`,
            }}
          >
          </div>
          <div className="bg-overlay"></div>
          <div className="slider-content">
            <div className="slider-container-wrapper">
              <div className="container">
                <MovingComponent
                  duration={"1000ms"}
                  delay={"300ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"300ms"}
                    animation={"zoomIn"}
                  >
                    <h3 className="slider-title">
                    Our AI recommendations EXPLAINED...
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"1000ms"}
                  delay={"500ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"500ms"}
                    animation={"slideInFromTop"}
                  >
                    <h3 className="slider-subtitle">
                    Unlike other AI solutions, the Digital Intern provides rationale for its recommendations making it an integral partner in care team <span style={{fontWeight:'bold', fontStyle:'italic'}}>communication</span>
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"500ms"}
                  delay={"800ms"}
                  animation={"slideInFromTop"}
                >
                  <LearnMoreButton to={"/about"} />
                </MovingComponent>
                <div className="row slider-containerflexbox">
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1200ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={decisionmakingwhite} />
                      <p>augments bedside decision making</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1300ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={recommendwhite} />
                      <p>provides step-by-step recommendations</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1400ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={variabilitywhite} />
                      <p>reduces variability</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1500ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={moneywhite} />
                      <p>reduces costs</p>
                    </MultiMovingComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const SlideContent5 = () => {
  const swiperSlide = useSwiperSlide();
  return (
    <>
      {swiperSlide.isActive ? (
        <>
          <div
            className="xx"
            style={{
              backgroundImage: `url(${sliderImage5})`,
            }}
          ></div>
          <div className="bg-overlay"></div>
          <div className="slider-content">
            <div className="slider-container-wrapper">
              <div className="container">
                <MovingComponent
                  duration={"1000ms"}
                  delay={"300ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"300ms"}
                    animation={"zoomIn"}
                  >
                    <h3 className="slider-title">
                    Augmenting the care team
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"1000ms"}
                  delay={"500ms"}
                  animation={"fadeIn"}
                >
                  <MovingComponent
                    duration={"500ms"}
                    delay={"500ms"}
                    animation={"slideInFromTop"}
                  >
                    <h3 className="slider-subtitle">
                    The Digital Intern augments the care your team provides without replacing their insights.
                    </h3>
                  </MovingComponent>
                </MovingComponent>
                <MovingComponent
                  duration={"500ms"}
                  delay={"800ms"}
                  animation={"slideInFromTop"}
                >
                  <LearnMoreButton to={"/solutions"} />
                </MovingComponent>
                <div className="row slider-containerflexbox">
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1200ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={decisionmakingwhite} />
                      <p>augments bedside decision making</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1300ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={recommendwhite} />
                      <p>provides step-by-step recommendations</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1400ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={variabilitywhite} />
                      <p>reduces variability</p>
                    </MultiMovingComponent>
                  </div>
                  <div className="col-3">
                    <MultiMovingComponent
                      duration={300}
                      carpan={2}
                      delay={"1500ms"}
                      animations={["fadeIn", "slideInFromTop"]}
                    >
                      <img src={moneywhite} />
                      <p>reduces costs</p>
                    </MultiMovingComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const Slider = () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        threshold={80}
        spaceBetween={50}
        slidesPerView={"auto"}
        autoplay={{
          delay: 7500,
          disableOnInteraction: false,
        }}
        navigation
        pagination={{ clickable: true }}
        style={{ position: "relative", top: "-80px" }}
        loop={true}
      >
        <SwiperSlide className="img">
          <div
            style={{
              width: "100%",
              height: "100vh",
            }}
          >
            <SlideContent1 />
          </div>
        </SwiperSlide>
        <SwiperSlide className="img">
          <div style={{ width: "100%", height: "100vh", textAlign: "center" }}>
            <SlideContent2 />
          </div>
        </SwiperSlide>
        <SwiperSlide className="img">
          <div style={{ width: "100%", height: "100vh", textAlign: "center" }}>
            <SlideContent3 />
          </div>
        </SwiperSlide>
        <SwiperSlide className="img">
          <div style={{ width: "100%", height: "100vh", textAlign: "center" }}>
            <SlideContent4 />
          </div>
        </SwiperSlide>
        <SwiperSlide className="img">
          <div style={{ width: "100%", height: "100vh", textAlign: "center" }}>
            <SlideContent5 />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Slider;
