import React, { useEffect, useState } from "react";
import { LocalNavContainer, NavItemWrapper } from "./ModulesNav.style";

import renal from "./statics/renal.png";
import neuro from "./statics/neuro.png";
import blood from "./statics/blood.png";
import nutrition from "./statics/nutrition.png";
import cardiovascular from "./statics/cardiovascular.png";
import electrolytes from "./statics/electrolytes.png";
import pain from "./statics/pain.png";
import pulmonary from "./statics/pulmonary.png";
import back from "./statics/back.png";
import { useLocation } from "react-router-dom";

const ModulesNav = () => {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    
    if (pathname.split("/").length > 2) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [pathname]);

  return (
    <>
      <LocalNavContainer>
        {toggle ? (
          <NavItemWrapper href={"/digital-intern-modules"}>
            <img src={back} alt="" />
            <p>Overview</p>
          </NavItemWrapper>
        ):""}
        <NavItemWrapper href={"/digital-intern-modules/renal"}>
          <img src={renal} alt="" />
          <p>Renal</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/neuro-cns"}>
          <img src={neuro} alt="" />
          <p>Neuro/CNS</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/blood"}>
          <img src={blood} alt="" />
          <p>Blood</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/gi-nutrition"}>
          <img src={nutrition} alt="" />
          <p>GI / Nutrition</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/cardiovascular"}>
          <img src={cardiovascular} alt="" />
          <p>Cardiovascular</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/electrolytes"}>
          <img src={electrolytes} alt="" />
          <p>Electrolytes</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/pain-sedation"}>
          <img src={pain} alt="" />
          <p>Pain/Sedation</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/digital-intern-modules/pulmonary"}>
          <img src={pulmonary} alt="" />
          <p>Pulmonary</p>
        </NavItemWrapper>
      </LocalNavContainer>
    </>
  );
};

export default ModulesNav;
