import React from 'react'
import MyAccordion from "../../components/accordion/MyAccordion";
import PageTitle from '../../components/pageTitle/PageTitle';
import SEO from '../../components/seo/SEO';


const FrequentlyAskedQuestions = () => {
  return (
    <>
    <SEO
        title="Frequently Asked Questions"
        description="Frequently Asked Questions"
      />
    <PageTitle
        title={"Frequently Asked Questions"}
        breadcrumb={[
          {
            url: "/",
            desc: "Home",
          },
          {
            url: "/frequently-asked-questions",
            desc: "Frequently Asked Questions",
          },
        ]}
      />
      <div className="container mt-5">
        <MyAccordion
          
          content={[
            {
              title:
                "Does the Digital Intern® use Artificial Intelligence or Machine Learning?",
              desc: [
                ` Artificial Intelligence is defined as “the theory and development
                of computer systems able to perform tasks that normally require
                human intelligence, such as visual perception, speech recognition,
                decision-making, and translation between languages.” The concept
                was developed by Alan Turing in 1950. There are many other
                definitions for AI however as the field of study is ever changing
                and expanding.`,
                `Machine Learning is a subset of artificial intelligence and is
                defined as “the study of algorithms and mathematical models that
                computer systems use to progressively improve their performance on
                a specific task.” With that definition however, a computer
                algorithm with predetermined weights, set by a priori knowledge,
                could be considered to have learned. Thus, a more recent
                definition has specifically addressed machine learning as a field
                of artificial intelligence that uses statistical techniques to
                give computer systems the ability to “learn” from data, without
                being explicitly programmed on how to interpret that data.`,

                `Often adaptive algorithms are synonymous with machine learning.
                However, not all adaptive algorithms need to have been learned by
                a machine that is analyzing data. If an algorithm takes into
                consideration specific parameters and one or more responses to at
                least one of those parameters is used to vary the output of the
                algorithm, the algorithm can be considered adaptive. As an
                example, if an algorithm considers prior responses to blood
                transfusions of multiple patients and predicts a transfusion
                amount will last for a presumed period of time for an individual
                patient, its adaptability may be very limited. However, if the
                algorithm uses patient specific prior responses and only those
                patient’s responses are used to predict a response to a
                transfusion, the algorithm becomes more adaptive. In this manner
                the algorithm does not give an overall result for all patients, it
                gives a result that is tailored for the individual patient. This
                has considerably more power to deliver on the idea of precision
                medicine.`,
                `If an algorithm is designed by a human rather than a machine, it
                is not truly considered machine learning. It is still artificial
                intelligence because there is intelligence built in to the system
                that can be communicated out of the system. Had the mathematical
                approach with its weights and general shape been chosen and
                optimized by a machine, then machine learning can be said to have
                been employed in the process. The Digital Intern® is adaptive and
                yields patient specific results but it does not truly involve
                machine learning.`,
              ],
              expand: "a",
            },
            {
              title:
                "What are the Digital Intern® algorithms based on?",
              desc: [
                ` The Digital Intern® makes decisions based on best practices in
                literature, human physiology, and cost savings strategies where
                appropriate. Decisions are generally not made in isolation and
                often multiple Digital Intern® modules will work together to
                address the needs of the patient.`,
              ],
              expand: "ab",
            },
            {
              title: "Is the Digital Intern® FDA approved?",
              desc: [
                ` We went through the QSub process with the FDA and when we were
                submitting our final application the 21 st Century Cures Act was passed. This resulted in the FDA
                response to not regulate us as long as we were “open loop” meaning that the Digital Intern doesn’t
                directly control IV pumps or the ventilator, that Digital Intern gives its recommendation to trained
                medical personnel only (not to patients), and that the rationale for clinical decisions is made available to
                users of the Digital Intern.`,
                // ` We’ve worked with the FDA and determined that the Digital Intern® is a clinical decision support system which does not currently require FDA approval or clearance.`,
              ],
              expand: "abc",
            },
            {
              title: "Can the Digital Intern® integrate with… ?",
              desc: [
                `The Digital Intern® has standard HL7 interfacing capabilities
                which allows us to connect with any system using these standard specifications. This
                includes most electronic medical record systems. We’ve also successfully created
                custom interfaces to connect with other systems such as OPOs that do not have HL7
                capabilities. Our newest technology, the Digital Intern Octane®, runs on an iPad and is
                completely self-contained and does not require internet access. The Digital Intern
                Octane® is perfect for EMS, and air and ground medical transport programs. Upon
                arriving at the hospital, the Digital Intern Octane® can share what it had learned about
                the patient with other Digital Intern Octane® systems or the Digital Intern® Engine which is
                integrated with the medical record system. Let us work with you to integrate with the
                systems you already use.. Click here to contact us so that we can help you optimize
                patient care and workflows for your nurses, respiratory therapists, and other providers.`
                //` Probably! We have standard HL7 interfacing capabilities which allow us to connect with other systems using these standard specifications which include most electronic medical record systems. We’ve also successfully created custom interfaces to connect with a few electronic medical record systems used by OPOs that do not have HL7 capabilities. We’re always open to working with you to integrate with the systems you already use whenever possible. The Digital Intern® is most useful when its integrated into a provider’s existing workflow.`,
              ],
              expand: "b",
            },
            {
              title:
                "Can the Digital Intern® help with problems related to therapeutic duplication?",
              desc: [
                ` Yes! The Digital Intern®’s consistent approach can help resolve issues with non-specific orders that cause issues with therapeutic duplication while still ensuring each patient’s individual needs are met. Your providers will answer a few quick questions about their goals for the patient when placing orders, but can rely on the Digital Intern®’s algorithms to help select appropriate medications at the right time. ***/solutions/therapeutic-duplication&&&Learn more here.`,
              ],
              expand: "c",
            },
            {
              title: "How much does the Digital Intern® cost?",
              desc: [
                `The Digital Intern® is priced based on the number
                of beds that a hospital has and the algorithms desired, the number of transport vehicles
                for transport systems, and for the size of the OPO where organ procurement is involved.
                We do not price based on the number of users or devices in use. Packages are available
                particularly for de-identified data sharing. ***/contact&&&Contact us*** for more information.`
              ],
              expand: "d",
            },
            {
              title:
                "My organization hasn’t purchased the Digital Intern®. How can I see it in action?",
              desc: [
                `You can see a small sampling of modules for
                demonstration on our website. We simulate a generic electronic medical record and you
                can play with the algorithms to see what they can do for patients and your medical
                team. The demonstration requires some quick data entry which would not generally be
                needed in a real electronic medical record system. Please note that both the web
                demonstration tools and the educator apps which are available for download from the
                Apple App Store are not for use with actual patients or patient data as each platform’s playground is not designed to secure Protected Health Information. Keep in mind that the
                Digital Intern® should not replace your own medical decision making and judgment but
                instead should be used to augment your skills in caring for patients.`,

                `Consider ***/contact&&&contacting us*** to setup a presentation to your care and administrative teams.
We will be able to answer your questions about how the various modules work and how
they can be implemented at your organization. We are available for presentations in
person or remotely.`,
              ],
              expand: "e",
            },
            {
              title:
                "I think my organization could benefit from the Digital Intern®. How can I help bring this technology to my work place?",
              desc: [
                ` We are thrilled that you are interested in using the Digital
                Intern®! We will need to get in touch with leadership at your
                organization to help make this happen. Please fill out this
                ***/contact&&&contact form*** with as much information as possible and we’ll reach
                out to setup a meeting.`,
              ],
              expand: "f",
            },
            
            {
              title:
                "How did you decide which modules to build?",
              desc: [
                ` The Digital Intern® algorithms were designed to address the needs
                of many areas of medical care. The decisions to build specific
                Digital Intern® algorithms were based on high yield medical cost
                reduction strategies that are realizable in a measurable manner,
                the need to meet specific CMS/JCAHO requirements, and to tackle
                problems where quantifiable data is recorded within the EMR and
                where the resulting calculations can yield a quantifiable result.`,
              ],
              expand: "l",
            },
            {
              title:
                "Is the Digital Intern cloud based?",
              desc: [
                `The Digital Intern is NOT cloud based. It resides on the servers you use for your electronic medical
                record and does not communicate through your firewall to the internet. In this manner your patient’s
                PHI is maximally protected. Should your organization be willing to share data with us on the Digital
                Intern’s performance, we would direct you to our upload site on a quarterly basis where the de-
                identified data can be manually transferred to us for review.`,
              ],
              expand: "m",
            },
          ]}
        />
      </div>
    </>
    
  )
}

export default FrequentlyAskedQuestions