import React, { useEffect, useState } from "react";


const CoolButton = ({ p, color }) => {
  const [length, setLength] = useState(null);

  return (
    <svg style={{ width: "100%" }} viewBox="0 0 282 145">
      <defs>
        <linearGradient
          id="buttoncolor"
          x1="0"
          y1="72.5"
          x2="282"
          y2="72.5"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".2" stopColor="#f56772" />
          <stop offset=".33303" stopColor="#d26d97" />
          <stop offset=".55058" stopColor="#9e77cf" />
          <stop offset=".71273" stopColor="#7e7df1" />
          <stop offset=".8" stopColor="#7280ff" />
        </linearGradient>
      </defs>
      <path
        style={{
          strokeDasharray: length,
          strokeDashoffset: (1 - p) * length,
        }}
        stroke={color ? color : "url(#buttoncolor)"}
        strokeWidth={p > 0.8 ? `${8 + (p - 0.8) * 5 * 10}` : "8"}
        d="M279,3V142H3V3H279m3-3H0V145H282V0h0Z"
        fill="none"
        ref={(ref) => {
          if (ref) {
            setLength(ref.getTotalLength());
          }
        }}
      />
    </svg>
  );
};

export default CoolButton;
