import React from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import SEO from "../../components/seo/SEO";
import "./DemoPlayground.css";

const DemoPlayground = () => {
  return (
    <>
    <SEO
        title="Demo / Playground"
        description="The Digital Intern® Web Demonstrations provide access to select
        Digital Intern® modules through a web-based tool. This for
        demonstration purposes only and is not intended for use with
        patients. You can use these tools to see the types of
        recommendations a Digital Intern® module will give, but you are not
        to rely on these tools for medical practice or any other purpose.
        Since these demonstration tools are not integrated with any medical
        record system, you must manually enter the required data. This data
        is not stored securely or made available for later reporting or any
        other purpose. You must not enter any identifying patient
        information into these tools."
      />
      <PageTitle
        title={"Demo / Playground"}
        breadcrumb={[
          {
            url: "/",
            desc: "Home",
          },
          {
            url: "/demo-playground",
            desc: "Demo & Playground",
          },
        ]}
      />
      <div className="emrbutton-container">
        <div className="row text-center justify-content-center mt-4 p-2">
          <div className="col-lg-6 col-md-9">
            The Digital Intern® Web Demonstrations provide access to select
            Digital Intern® modules through a web-based tool. This is for
            demonstration purposes only and is not intended for use with
            patients. You can use these tools to see the types of
            recommendations a Digital Intern® module will give, but you are not
            to rely on these tools for medical practice or any other purpose.
            Since these demonstration tools are not integrated with any medical
            record system, you must manually enter the required data. This data
            is not stored securely or made available for later reporting or any
            other purpose. You must not enter any identifying patient
            information into these tools.
          </div>
        </div>
        <div className="emrbutton-wrapper">
          <a
            className="emrbutton"
            href="https://ehr.ivmedicaldynamics.com/"
            target={"_blank"}
          >
            EHR Demonstration
          </a>
        </div>
      </div>
    </>
  );
};

export default DemoPlayground;
