import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import DigitalInternModules from "../pages/digitalInternModules/DigitalInternModules";
import FrequentlyAskedQuestions from "../pages/frequentlyAskedQuestions/FrequentlyAskedQuestions";
import Home from "../pages/home/Home";
/* About */
import History from "../pages/about/history/History";
import NewsPublications from "../pages/about/newsPublications/NewsPublications";
import Team from "../pages/about/team/Team";
/* Solutions */
import BloodUseManagement from "../pages/solutions/bloodUseManagement/BloodUseManagement";
import DigitalInternEducator from "../pages/solutions/digitalInternEducator/DigitalInternEducator";
import OrganProcurement from "../pages/solutions/organProcurement/OrganProcurement";
import Overview from "../pages/solutions/overview/Overview";
import SupportiveCare from "../pages/solutions/supportiveCare/SupportiveCare";
import TherapeuticDuplication from "../pages/solutions/therapeuticDuplication/TherapeuticDuplication";
import Contact from "../pages/contact/Contact";
import DemoPlayground from "../pages/demoPlayground/DemoPlayground";
import Header from "../components/header/Header";
import GlobalStyle from "../components/globalStyles/GlobalStyles";
import Footer from "../components/footer/Footer";
import Catalog from "../pages/solutions/catalog/Catalog";
import DigitalIntern from "../pages/about/digitalIntern/DigitalIntern";
import Renal from "../pages/digitalInternModules/renal/Renal";
import NeuroCns from "../pages/digitalInternModules/neuroCns/NeuroCns";
import Blood from "../pages/digitalInternModules/blood/Blood";
import GiNutrition from "../pages/digitalInternModules/giNutrition/GiNutrition";
import Cardiovascular from "../pages/digitalInternModules/cardiovascular/Cardiovascular";
import Electrolytes from "../pages/digitalInternModules/electrolytes/Electrolytes";
import PainSedation from "../pages/digitalInternModules/painSedation/PainSedation";
import Pulmonary from "../pages/digitalInternModules/pulmonary/Pulmonary";
import ScrollToTop from "../components/scrollToTop/ScrollToTop";

const AppRouter = () => {
  return (
    <BrowserRouter>
      
      <ScrollToTop/>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
        
        <Route path="frequently-asked-questions" element={<FrequentlyAskedQuestions />} />
        <Route path="contact" element={<Contact />} />
        <Route path="demo-playground" element={<DemoPlayground/>} />

        {/* Modules */}
        <Route path="digital-intern-modules" element={<Outlet />}>
          <Route index element={<DigitalInternModules/>} />
          <Route path="renal" element={<Renal/>} />
          <Route path="neuro-cns" element={<NeuroCns/>} />
          <Route path="blood" element={<Blood/>} />
          <Route path="gi-nutrition" element={<GiNutrition/>} />
          <Route path="cardiovascular" element={<Cardiovascular/>} />
          <Route path="electrolytes" element={<Electrolytes/>} />
          <Route path="pain-sedation" element={<PainSedation/>} />
          <Route path="pulmonary" element={<Pulmonary/>} />
        </Route>

        {/* About */}
        <Route path="about" element={<Outlet />}>
          <Route index element={<DigitalIntern/>} />
          <Route path="history" element={<History />} />
          <Route path="news-publications" element={<NewsPublications />} />
          <Route path="team" element={<Team />} />
          <Route path="digitalintern" element={<DigitalIntern/>} />
        </Route>
        

        {/* Solutions */}
        <Route path="solutions" element={<Outlet />}>
          <Route index element={<Overview />} />
          <Route path="overview" element={<Overview />} />
          <Route path="catalog" element={<Catalog />} />
          <Route path="blood-use-management" element={<BloodUseManagement />} />
          <Route path="organ-procurement" element={<OrganProcurement />} />
          <Route
            path="therapeutic-duplication"
            element={<TherapeuticDuplication />}
          />
          <Route path="supportive-care" element={<SupportiveCare />} />
          <Route
            path="digital-intern-educator"
            element={<DigitalInternEducator />}
          />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
