import React from "react";
import Module from "../../../components/module/Module";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import SEO from "../../../components/seo/SEO";

const Pulmonary = () => {
  return (
    <div style={{minHeight:"80vh"}}>
      <SEO
        title="Digital Intern Modules / Pulmonary"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Pulmonary/ Ventilation management"}
          text={[
            `Includes recommendations for all modes of ventilation and associated settings`,
            `Includes adjunctive therapies such as nebulizers, intrapulmonary percussive ventilation, in-exsufflator, vest therapy, and inhaled medications`,
          ]}
          src={
            "https://drive.google.com/file/d/1NFrpcq3ZzNrdxErMl5aoSaSzVw_fz1ht/view?usp=sharing"
          }
        />
        <Module
          title={"Secretion management"}
          text={[`Useful for patients with copious oral secretions`]}
          src={
            "https://drive.google.com/file/d/1O37ZS5jfz3oLaq4wOh7_uaTG9tr9sjmg/view?usp=sharing"
          }
        />
        <Module
          title={"Prevention of Ventilator Associated Pneumonia"}
          text={[
            `Changing of suction tubing`,
            `HOB position`,
            `OGT placement`,
            `Oral care package`,
          ]}
          src={
            "https://drive.google.com/file/d/1wl3LaM51I1ULsEUFFQff31taB_uwbUDG/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default Pulmonary;
