import React from "react";
import { TvContainer, VideoBottom, VideoContainer, VideoSubWrapper, VideoWrapper } from "./Tv.style";
import tvx from "./tv.png"
const Tv = ({children,className,style}) => {
  return (
    <VideoContainer id="videocontainer">
      <VideoWrapper className={className} style={style}>
        <VideoSubWrapper>
          {children}
        </VideoSubWrapper>

        <TvContainer>
          <img src={tvx} alt="" />
        </TvContainer>
      </VideoWrapper>
     
    </VideoContainer>
  );
};

export default Tv;
