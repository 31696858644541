import React from "react";
import { LocalNavContainer, NavItemWrapper } from "./AboutNav.style";
import publications from "./statics/publicationsblue.png";
import team from "./statics/teamblue.png";
import digitalintern from "./statics/rythm.png";
import history from "./statics/book.png";
const AboutNav = () => {
  return (
    <>
      <LocalNavContainer>
        <NavItemWrapper href={"/about/digitalintern"}>
          <img src={digitalintern} alt="" />
          <p>Digital Intern</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/about/history"}>
          <img src={history} alt="" />
          <p>History</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/about/team"}>
          <img src={team} alt="" />
          <p>Team</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/about/news-publications"}>
          <img src={publications} alt="" />
          <p>News and Publications</p>
        </NavItemWrapper>
      </LocalNavContainer>
    </>
  );
};

export default AboutNav;
