import React, { useState } from "react";
import {
  MemberDesc,
  MemberInfoWrapper,
  MemberWrapper,
  MemberJob,
  MemberName,
} from "./TeamMember.style";
import expandImg from "./images/bottomexpand.png";
const TeamMember = ({ name, job, text, img, largetext }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <div className="col-12 mb-5">
        <MemberWrapper expanded={expanded}>
          <div>
            <img
              className="team-img"
              src={img}
              style={{ borderRadius: "4px" }}
            />
          </div>
          <div>
            <MemberInfoWrapper expanded={expanded}>
              <MemberName>{name}</MemberName>
              <MemberJob>{job}</MemberJob>

              <MemberDesc>{text}</MemberDesc>
            </MemberInfoWrapper>
            {largetext ? (
              <>
                <div className="fadex" src={expandImg}  onClick={()=>{
                  setExpanded(true)
                }}></div>
                <img className="expand" src={expandImg}  onClick={()=>{
                  setExpanded(true)
                }}/>
              </>
            ) : (
              ""
            )}
          </div>
        </MemberWrapper>
      </div>
    </>
  );
};

export default TeamMember;
