import styled from 'styled-components'


export const VideoBottom = styled.div`
  display: block;
  margin-top: var(--p-height);
  --p-height: 510px;
  @media only screen and (max-width: 1068px) and (max-width: 1068px) {
    --p-height: 382px;
  }
  @media only screen and (max-width: 734px) and (max-width: 734px) {
    --p-height: 0px;
  }
`;

export const VideoContainer = styled.div`
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  position: relative;
  --p-width: 906px;
  --p-height: 510px;
  @media only screen and (max-width: 1068px) and (max-width: 1068px) {
    --p-width: 680px;
    --p-height: 382px;
  }
  @media only screen and (max-width: 734px) and (max-width: 734px) {
    --p-width: 426px;
    --p-height: 240px;
  }
  @media only screen and (max-width: 450px) and (max-width: 450px) {
    --p-width: 300px;
    --p-height: 169px;
  }
`;

export const VideoWrapper = styled.div`
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 906px;
  --p-height: 510px;
  position: absolute;
  left: 0;
  @media only screen and (max-width: 1068px) and (max-width: 1068px) {
    --p-width: 680px;
    --p-height: 382px;
  }
  @media only screen and (max-width: 734px) and (max-width: 734px) {
    --p-width: 426px;
    --p-height: 240px;
  }
  @media only screen and (max-width: 1068px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 734px) {
    top: 0;
    left: -2px;
    position: relative;
  }
  @media only screen and (max-width: 450px) and (max-width: 450px) {
    --p-width: 300px;
    --p-height: 169px;
  }
`;

export const VideoSubWrapper = styled.div`
  overflow: hidden;
  transform-origin: bottom;

  display: block;
  width: var(--p-width);
  height: var(--p-height);
  --p-width: 906px;
  --p-height: 510px;
  position: absolute;
  z-index: 2;
  left: 6px;
  top: 0;
  @media only screen and (max-width: 1068px) and (max-width: 1068px) {
    --p-width: 680px;
    --p-height: 382px;
  }
  @media only screen and (max-width: 734px) and (max-width: 734px) {
    --p-width: 426px;
    --p-height: 240px;
  }
  @media only screen and (max-width: 1068px) {
    left: 3px;
  }
  @media only screen and (max-width: 734px) {
    left: 4px;
    top:3px;
  }
  @media only screen and (max-width: 450px) and (max-width: 450px) {
    --p-width: 300px;
    --p-height: 169px;
  }
`;

export const TvContainer = styled.div`
  display: block;
  width: var(--p-width);
  height: var(--p-height);
  margin-top: calc(var(--frame-offset-vertical) * -1);
  --frame-offset-vertical: 6px;
  --p-width: 1000px;
  --p-height: 602px;
  @media only screen and (max-width: 1068px) and (max-width: 1068px) {
    --frame-offset-vertical: 3px;
    --p-width: 748px;
    --p-height: 450px;
  }
  @media only screen and (max-width: 734px) and (max-width: 734px) {
    --frame-offset-vertical: 4px;
    --p-width: 470px;
    --p-height: 284px;
  }
  @media only screen and (max-width: 450px) and (max-width: 450px) {
    --p-width: 300px;
    --p-height: 202px;
  }
  img {
    height: calc(var(--p-height) + 1px);
  }
`;