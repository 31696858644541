import React from "react";
import AboutNav from "../../../components/aboutnav/AboutNav";
import SEO from "../../../components/seo/SEO";
import Tv from "../../../components/tv/Tv";
import { UnderLinedText } from "../../../components/underLinedText/UnderLinedText.style";
import digitalintern from "./digitalintern.png";

const DigitalIntern = () => {
  return (
    <>
      <SEO
        title="Digital Intern"
        description="The Digital Intern® is an AI, clinical decision support software platform that delivers meaningful, real-time recommendations and important calculations to providers in hospital and medical transport settings."
      />
      <AboutNav />
      <section>
        <div className="container mt-4 mb-5">
          <div className="row text-center justify-content-center mb-5">
            <div className="col-lg-6">
              <strong>
                The Digital Intern® is a new software product designed and
                developed to aid your organization in improving adherence to
                best clinical practices, reducing costs, increasing efficiency
                and reducing physicians’ workload.
              </strong>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <img
                src={digitalintern}
                alt=""
                className="my-4"
                style={{ maxWidth: "100%" }}
              />
            </div>

            <div className="col-lg-6">
              <UnderLinedText>
                <h1>About the Digital Intern®</h1>
              </UnderLinedText>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-6">
                  <p style={{ fontSize: "20px", marginBottom: "10px" }}>
                    Automated Decision-Making for Healthcare
                  </p>
                  The Digital Intern® is an AI, clinical decision support
                  software platform that delivers meaningful, real-time
                  recommendations and important calculations to providers in
                  hospital and medical transport settings. Physicians decide
                  patient goals for vital signs, labs, ventilation, comfort, and
                  nutrition and the Digital Intern® engine communicates
                  recommendations to nursing and respiratory therapy staff as a
                  part of their normal EHR workflow. The Digital Intern®
                  performs like an EICU without the human resources component.
                  Unlike other AI solutions, the Digital Intern® provides
                  supportive care to prevent complications rather than
                  predicting when an adverse event will occur.
                </div>

                <div className="col-lg-6">
                  <p style={{ fontSize: "20px", marginBottom: "10px" }}>
                    Deployable, Highly Efficient AI Without the Need for a
                    Server or Network
                  </p>
                  The Digital Intern Octane® offers the same level of clinical
                  decision support that the EHR deployed version provides and
                  does so entirely on a tablet. Clinicians are enabled to manage
                  a cardiac arrhythmia, elevated ICP, or ARDS in the middle of
                  nowhere, the Digital Intern Octane® will use the resources and
                  information that is available to provide effective patient
                  treatment solutions. A perfect match for medical transport
                  providers who may not have easy access to a critical care
                  physician when one is needed. Once reconnected, the Digital
                  Intern Octane® will communicate new learnings about each
                  patients’ medical responses to the Digital Intern® to create
                  smooth and efficient handoffs.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-5 d-flex justify-content-center" desc="Watch as the inventor of the Digital Intern® gives a brief overview of
          the software and the many modules available:">
          <Tv>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/FroBmZK7YKQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Tv>
        </div>
    </>
  );
};

export default DigitalIntern;
