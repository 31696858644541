import styled from "styled-components";
import content_texture from "./images/content_texture.png";

export const ItemWrapper = styled.div`
  border: 1px solid #f2f2f2;
  position: relative;
  overflow: hidden;
  padding: 20px 15px;

  img{
    max-width: 120px;
    max-height: 40px;
  }
  .bigtitle {
    font-size: 13px;
    padding-top: 15px;
  }
  .bigtitle.clickable:hover {
    color: #06c;
  }

  .smalltitle {
    font-size: 9px;
  }
  .names {
    display: flex;
    gap: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
    flex-wrap: wrap;
    line-height: 0.8;
    span{

    }
  }
`;

export const Title =styled.h2`
  margin: 20px 0 30px 0;
`

export const Content = styled.div`
  max-width: 670px;
  color: #333;
  background: #fff;
  padding: 10px;
  margin: 20px 20px 20px 20px;
  position: relative;
  a{
    color: #333;
    text-decoration: none;
  }
  img {
    margin: 10px 0 2px 0;
  }
  .yyy {
    font-size: 20px;
    font-weight: 500;
    margin: 0px 0 2px 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  @media only screen and (max-width: 600px) {
    .yyy {
      font-size: 15px;
      font-weight: 500;
      margin: 0px 0 2px 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  .xxx {
    font-size: 12px;
    color: #000 !important;
    text-decoration: none;
    line-height: 1.3;
    letter-spacing: -0.1px;
    margin: 0px 0 2px 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  .names {
    display: flex;
    gap: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
  }
`;


export const NewDate = styled.div`
  width: 100%;
  border-bottom: 2px solid #03365f;
  margin-top: 10px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  span {
    color: #03365f;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const BgWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BgContent = styled.div`
  background: url(${content_texture}) repeat scroll left top transparent;
  padding: 15px 0;
  height: 3000px;
  width: 100%;
  position: absolute;
  z-index: -2;
`;

