import React from "react";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import Module from "../../../components/module/Module";
import SEO from "../../../components/seo/SEO";

const Electrolytes = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
      <SEO
        title="Digital Intern Modules / Electrolytes"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Adrenal insufficiency"}
          text={[
            `Escalates and de-escalates hydrocortisone dosing given size and level of insufficiency`,
            `Interfaces with fluids, electrolytes, and cariovascular modules to optimize care`,
          ]}
          src={
            "https://drive.google.com/file/d/10nC22aJq4gvrgY7YZj4YWvqTuMQx0qft/view?usp=sharing"
          }
        />
        <Module
          title={
            "Electrolyte management (calcium, potassium, phosphate, sodium, magnesium)"
          }
          text={[
            `Recommends corrective replacement or removal using various medications and routes of delivery`,
            `Interfaces with fluids and ICP management modules`,
          ]}
          src={
            "https://drive.google.com/file/d/1CXviOk_08XaQ0_1SGXxSqfhEuzNGqh8h/view?usp=sharing"
          }
        />
        <Module
          title={"Hyperglycemia and hypoglycemia"}
          text={[
            `Uses a patient’s current glucose level and their previous responses to insulin to adjust an insulin infusion appropriate for the patient’s individual insulin resistance`,
          ]}
          src={
            "https://drive.google.com/file/d/1JLMZLiAGLM2s3Vwn7ZEdLLDiXY-78F7O/view?usp=share_link"
          }
        />
        <Module
          title={"Hypothyroidism"}
          text={[
            `Recommends oral and/ or IV replacement using levothyroxine`,
            `Interfaces with cardiovascular module`,
          ]}
          src={
            "https://drive.google.com/file/d/1NlOubLDzN-0gKefLZTqvy3D3OKoxxLf1/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default Electrolytes;
