import React from "react";
import Module from "../../components/module/Module";
import { ModulesWrapper } from "../../components/module/Module.style";
import ModulesNav from "../../components/modulesnav/ModulesNav";
import SEO from "../../components/seo/SEO";

const DigitalInternModules = () => {
  return (
    <>
      <SEO
        title="Digital Intern Modules"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <div className="container">
        <ModulesWrapper nonfixed={"true"}>
          <Module
            nonfixed={true}
            title={"Renal"}
            text={[`Foley Catheter Management`, `Rhabdomyolysis Management`]}
            src={"/digital-intern-modules/renal"}
          />

          <Module
            nonfixed={true}
            title={"Neuro/CNS"}
            text={[
              `Intracranial Pressure Management`,
              `Pentobarbital Coma Management`,
              `Temperature Management`,
            ]}
            src={"/digital-intern-modules/neuro-cns"}
          />

          <Module
            nonfixed={true}
            title={"Blood"}
            text={[
              `Packed Red Cell Use`,
              `Other Blood Product Use`,
              `Reversal of Coagulopathy`,
              `Anticoagulation`,
            ]}
            src={"/digital-intern-modules/blood"}
          />
          <Module
            nonfixed={true}
            title={"GI / Nutrition"}
            text={[`GI Bleeding`, `Enteric Nutrition`, `Bowel Management`]}
            src={"/digital-intern-modules/gi-nutrition"}
          />
          <Module
            nonfixed={true}
            title={"Cardiovascular"}
            text={[
              `Atrial & Ventrcular Arrhythmias`,
              `Hemodynamic Management`,
              `Fluid Management`,
              `Lactate Management`,
            ]}
            src={"/digital-intern-modules/cardiovascular"}
          />

          <Module
            nonfixed={true}
            title={"Electrolytes"}
            text={[
              `Adrenal Insufficiency`,
              `Electrolyte Management`,
              `Insulin Infusion / Hyperglycemia & hypoglycemia`,
              `Hypothyroidism`,
            ]}
            src={"/digital-intern-modules/electrolytes"}
          />

          <Module
            nonfixed={true}
            title={"Pain/Sedation"}
            text={[
              `Pain & Agitation Management`,
              `Dysautonmia/neurostorming management`,
              `Alcohol Withdrawal`,
            ]}
            src={"/digital-intern-modules/pain-sedation"}
          />

          <Module
            nonfixed={true}
            title={"Pulmonary"}
            text={[
              `Pulmonary & Ventilation Management`,
              `Secretion Management`,
              `Prevention of Ventilator Associated Pneumonia`,
            ]}
            src={"/digital-intern-modules/pulmonary"}
          />
        </ModulesWrapper>
      </div>
    </>
  );
};

export default DigitalInternModules;
