import { Link } from "react-router-dom";
import styled from "styled-components";

export const LocalNavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background-color: #f5f5f7;
  margin: 0px 0px 0px 0px;
`;
export const NavItemWrapper = styled.a`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  
  &:not(:last-child)::after{
    content: "";
    width: 1.5px;
    height: 45px;
    right: -3px;
    position: absolute;
    background: rgba(0,0,0,0.2);
  }
  p {
      text-align: center;
      max-width: 100px;
    font-size: 12px;
    line-height: 1.3333733333;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #06c;
    display: block;
    padding: 0;
    margin-top: 3px;
    text-decoration: none;
  }
  img {
    height: 40px;
  }
  @media only screen and (max-width: 734px) {
    padding: 0px 10px;
    p {
        max-width: 70px;
      font-size: 10px;
      line-height: 1.3333733333;
      font-weight: 400;
      letter-spacing: -0.01em;
    }
    img {
      height: 30px;
    }
  }

  &:hover {
    p {
      color: #06c;
    }
  }
`;