import React, { useEffect, useState } from "react";
import SEO from "../../components/seo/SEO";

import contact from "./contact.jpeg";

const Contact = () => {
  
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  encodeURIComponent(subject)
  return (
    <>
    <SEO
        title="Contact"
        description="Get In Touch"
      />
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="text-uppercase">Get In Touch</h3>
              <p>
                For more information about iVMD or the Digital Intern®, contact
                us at{" "}
                <a href="mailto:info@ivmedicaldynamics.com">
                  info@ivmedicaldynamics.com
                </a>
                {" "}or fill out the form below.
                

              </p>
              <div className="m-t-30">
              
                <form className="widget-contact-form" >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        aria-required="true"
                        name="widget-contact-form-name"
                      
                        className="form-control  name"
                        placeholder="Enter your Name"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        aria-required="true"
                        name="widget-contact-form-email"
                        
                        className="form-control email"
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="form-group col-md-12">
                      <label for="title">Title</label>
                      <input
                        type="text"
                      
                        className="form-control "
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="form-group col-md-12">
                      <label for="subject">Your Subject</label>
                      <input
                        type="text"
                        onChange={(e)=>setSubject(e.target.value)}
                        name="widget-contact-form-subject"
                        required
                        className="form-control required"
                        placeholder="Subject..."
                      />
                    </div>
                  </div>
                  <div className="form-group my-3">
                    <label for="message">Message</label>
                    <textarea
                      type="text"
                      name="widget-contact-form-message"
                      required
                      rows="5"
                      onChange={(e)=>setMessage(e.target.value)}
                      className="form-control required"
                      placeholder="Enter your Message"
                    ></textarea>
                  </div>
                    <a className="btn btn-primary" href={`mailto:info@ivmedicaldynamics.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`}>
                    
                    <i className="fa fa-paper-plane"></i>&nbsp;Send message
                    </a>
                  
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={contact} alt="" style={{ width: "100%" }} />
              <p className="mt-3">
                <strong>Integrated Vital Medical Dynamics, LLC</strong>
              </p>

              <p className="mt-3">
                Join us in improving this exciting new advance in critical care.
              </p>
              <p className="mt-3">
                To learn more about becoming a beta site for the Digital
                Intern®, please contact us at{" "}
                <a href="mailto:info@ivmedicaldynamics.com">
                  info@ivmedicaldynamics.com
                </a>{" "}
                or fill out the form on this page.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
