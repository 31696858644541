import React from "react";
import { Link } from "react-router-dom";
import therapeuticDuplication from "./therapeuticDuplication.png";
import therapeuticDuplicationImage from "./therapeuticDuplicationImage.jpg";
import PdfCard from "../../../components/pdfCard/PdfCard";
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";
import PageTitle from "../../../components/pageTitle/PageTitle";
import SEO from "../../../components/seo/SEO";

const TextList = ({ text, strong }) => {
  return (
    <p
      style={{
        padding: "3px",
        fontSize: "12px",
        lineHeight: "1.5",
        margin: "0 0 0 10px",
      }}
    >
      <span style={{ padding: "10px", fontSize: "15px" }}>&bull;</span>
      {strong && <strong>{strong}</strong>}
      {text}
    </p>
  );
};

const TherapeuticDuplication = () => {
  const colCount = 1;
  const pdcards = [
    {
      colCount,
      img: therapeuticDuplication,
      url: "https://drive.google.com/file/d/1hxbhSNso7aYqtptxw6Q0vdu5vBySyqqW/view?usp=sharing",
    },
  ];
  return (
    <>
      <div style={{ minHeight: "80vh" }}>
        
        <PageTitle
        title={"Therapeutic Duplication Problems?"}
        title2={"The DIGITAL INTERN® can help."}
        breadcrumb={[
          { url: "/solutions", desc: "Solutions" },
          {
            url: "/solutions/therapeutic-duplication",
            desc: "Therapeutic Duplication",
          },
        ]}
      />
      <SEO
        title={"Therapeutic Duplication"}
        description={`Therapeutic Duplication Problems? The DIGITAL INTERN® can help.`}
      />
        <SolutionsNav />
        <div className="container mt-5">
          <div className="row  justify-content-center mb-2">
            <div className="col-lg-8">
              The Digital Intern’s algorithms provide a consistent approach to
              treating patients, while simultaneously learning the needs of the
              individual so that each patient gets what they need. The result is
              reproducible decision making based on the individual patient’s
              physiology backed by medical literature.
              <TextList
                text={
                  "Treatment of blood pressure and heart rate with multiple bolus or infusion medications"
                }
              />
              <TextList text={"Treatment of pain and agitation"} />
              <TextList text={"Bowel Medication Management"} />
              <TextList text={"Electrolyte Replacement"} />
            </div>
          </div>

          <div className="row  justify-content-center mb-2">
            <div className="col-lg-8">
              The <strong>Digital Intern</strong> suggests the appropriate dose
              and frequency (or infusion rate) from a list of medications on
              formulary to treat the patient’s individual needs. In doing so,
              the nurse isn’t choosing from a palette of possible medication
              options but rather is using what is recommended or rejecting the
              recommendation and contacting the provider for further assistance.
              In this manner, concerns over therapeutic duplication are
              eliminated.
              <img
                src={therapeuticDuplicationImage}
                style={{ width: "95%", margin: "30px 5% 20px 0" }}
              />
              The Digital Intern is:
              <TextList
                text={
                  "Goals (vital sign ranges, CPOT and RASS targets, etc.) are chosen by the provider for each patient."
                }
                strong={"Flexible - "}
              />
              <TextList
                text={
                  "The Digital Intern provides a consistent approach which reduces variability while still taking into account the different needs of individual patients."
                }
                strong={"Consistent – "}
              />
              <TextList
                text={
                  "The Digital Intern responds to new labs, vital signs, and clinical questions and recommends adjustments accordingly, learning more about the patient with each change."
                }
                strong={"Self-correcting – "}
              />
              <br />
              <a href={"/contact"} style={{ color: "green" }}>
                Contact us
              </a>{" "}
              today for more information about how the Digital Intern can help
              your team succeed.
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div style={{ maxWidth: "300px", width: "100%" }}>
            {pdcards.map((item) => (
              <PdfCard key={Math.random()*10000} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TherapeuticDuplication;
