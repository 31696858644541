import React from "react";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import Module from "../../../components/module/Module";
import SEO from "../../../components/seo/SEO";

const GiNutrition = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
       <SEO
        title="Digital Intern Modules / Gi Nutrition"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"GI bleed management"}
          text={[
            `Interfaces with patient blood use management modules`,
            `Escalates and de-escalates PPI medications (oral and IV)`,
          ]}
          src={
            "https://drive.google.com/file/d/1QANVG2Ey8sdLoQ88n_M7ZxLEldOvGFtx/view?usp=share_link"
          }
        />
        <Module
          title={"Enteric nutrition"}
          text={[
            `Suggests appropriate tube feed formula based on labs and target nutrient levels including protein, carbohydrate, and fat`,
            `Interfaces with pulmonary, fluids, and electrolyte algorithms and is able to change direction based on new onset or recovery of organ system failure (particularly liver, renal, and pulmonary)`,
          ]}
          src={
            "https://drive.google.com/file/d/18Y2HT72d5pmCkIf3l1FPPEgg1kq9Nnty/view?usp=share_link"
          }
        />
        <Module
          title={"Bowel Management"}
          text={[
            `Adjusts regimen to help with evacuation and optimization of bowel function`,
          ]}
          src={
            "https://drive.google.com/file/d/1LJ9ZFsjcwx-q--ivaYjrabtOaKG-96lQ/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default GiNutrition;
