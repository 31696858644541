import styled from "styled-components";

export const ChartContainerWrapper = styled.div`

  position: sticky;
  top: 0;
  padding-top: 100px;
  @media only screen and (max-width: 734px) {
    padding-top: 50px;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 80vh;
  width: 48vh;
  margin: auto;
  @media only screen and (max-width: 734px) {
    height: 158.33vw;
    width: 95vw;
  }
`;

export const ChartMask = styled.div`
  height: 0;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 100%;
  }
`;

export const BottomLineMask = styled.div`
  height: 0;
  width: 100%;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    position: absolute;
  }
`;

export const StartButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
`;
export const StartCenterTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  p {
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px 10px;
    border-radius: 5px;
  }
`;
export const CenterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
`;
export const CenterBottomTextContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  z-index: 1;
  width: 100%;
  p {
    font-size: 12px;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px 10px;
    border-radius: 5px;
    max-width: 110px;
  }
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  width: 100px;
  background: #fff;
  svg {
    position: relative;
    z-index: 1;
  }
`;

export const ButtonContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 8px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
`;

export const GradientButton = styled.h3`

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: #000;
  background-image: linear-gradient(90deg,#f56772 20%,#bc70ff 80%);
  -webkit-box-decoration-break: clone;
  font-style: normal;

`;



