import styled from "styled-components";

export const SmallTitle = styled.h2`
  font-size: 25px;
  font-weight: 400;
  line-height: 1.4;
`;

export const ImageWrapper = styled.div`
  position: relative;
  img {
    width: 100%;
  }
`;

export const PopoverContainer = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 10;
  .x1 {
    background: #000;
    width: 300px;
    padding: 10px;
    height: auto;
    position: absolute;
    font-size: 12px;
    line-height: 1.3;
    color: #000;
    bottom: 0px;
    left: -150px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    word-wrap: break-word;
    background-color: #f0f0f0;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
  }
  .popover-arrow {
    position: absolute;
    left: 0px;
    width: 16px;
    height: 8px;
  }
  .popover-arrow:before {
    content: "";
    position: absolute;
    border: solid 8px transparent;
    border-top-color: #f0f0f0;
    z-index: 1;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  max-width: 1100px;
  font-size: 14px;
  line-height: 1.5;
`;
export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
`;
export const FirstCol = styled.div`
  width: 520px;
  @media only screen and (max-width: 1028px) {
    width: 100%;
  }
`;
export const SecondCol = styled.div`
  width: calc(100% - 550px);
  @media only screen and (max-width: 1028px) {
    width: 100%;
  }
`;
