import React from "react";
import Module from "../../../components/module/Module";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import SEO from "../../../components/seo/SEO";

const PainSedation = () => {
  return (
    <div style={{minHeight:"80vh"}}>
      <SEO
        title="Digital Intern Modules / Pain Sedation"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Pain and agitation management"}
          text={[
            `Uses RASS, CPOT, and CNPI scores to attain goal level of sedation and pain control`,
            `Interfaces with ventilation, paralytic, and ICP modules`,
          ]}
          src={
            "https://drive.google.com/file/d/1Z5kItuCbt0igHVtLVa5CO-nY6laR34CX/view?usp=sharing"
          }
        />
        <Module
          title={"Dysautonmia/ neurostorming management"}
          text={[
            `Evaluates heart rate, blood pressure, rigidity, pain, and agitation and doses medications to treat symptomatology accordingly`,
          ]}
          src={
            "https://drive.google.com/file/d/1dSsMdMlbhCo9obsdB-_mqglCkQs6vtOB/view?usp=sharing"
          }
        />
        <Module
          title={"Alcohol withdrawal"}
          text={[
            `Uses gabapentin and atypical antipsychotic medications to help with control of withdrawal`,
          ]}
          src={
            "https://drive.google.com/file/d/1saSpVNgSpFXghTnuFurTP6cPMudKPsUB/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default PainSedation;
