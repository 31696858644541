import React from 'react'
import AboutNav from '../../../components/aboutnav/AboutNav'
import SEO from '../../../components/seo/SEO'
import { UnderLinedText } from '../../../components/underLinedText/UnderLinedText.style'
import about from "./about.jpeg"

const History = () => {
  return (
    <>
    <SEO
        title="History"
        description="Dr. Joshua Medow, a practicing critical care physician and
        neurosurgeon at the University of Wisconsin Hospital in
        Madison, WI, found himself surrounded by critically ill
        patients and with limited human resources to care for them.
        This often meant that he could not devote as much time as he
        would have liked to care for brain dead or unsurvivable
        patients who might be organ donors. With a unit full of
        patients with good chances to survive his attention was often
        diverted from potential donors. Knowing that each potential
        donor may save up to eight other lives, Dr. Medow was
        compelled to find a way to do more to help those recipients."
      />
    <AboutNav/>
    <section>
        <div className="container mt-4">
          
          <div className="row">
            <div className="col-lg-3">
              <UnderLinedText>
              <h1>About iVMD</h1>
              </UnderLinedText>
              
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-6">
                  Dr. Joshua Medow, a practicing critical care physician and
                  neurosurgeon at the University of Wisconsin Hospital in
                  Madison, WI, found himself surrounded by critically ill
                  patients and with limited human resources to care for them.
                  This often meant that he could not devote as much time as he
                  would have liked to care for brain dead or unsurvivable
                  patients who might be organ donors. With a unit full of
                  patients with good chances to survive his attention was often
                  diverted from potential donors. Knowing that each potential
                  donor may save up to eight other lives, Dr. Medow was
                  compelled to find a way to do more to help those recipients.
                  <img
                    src={about}
                    alt=""
                    className="my-4"
                    style={{ maxWidth: "100%" }}
                  />
                </div>

                <div className="col-lg-6">
                  Dr. Medow engaged IT expert, Tony Frey. Together they built
                  his flow charts into algorithms and placed them into the
                  hospital’s electronic medical record system with a series of
                  order sets, text instructions, and best practice alerts.
                  Functioning at the University of Wisconsin hospital for 5
                  years, it is now used to care for organ donors in all the
                  critical care units at the hospital.
                  <br /> <br />
                  As Dr. Medow’s algorithms proved successful, the team began to
                  develop similar algorithms which would assist in caring for
                  other critically ill patients. The company, iVMD, was created
                  to expand product offerings and to distribute those products
                  to other hospitals and organ procurement organizations across
                  the country.
                  <br /> <br />
                  Today, the Digital Intern is available for Organ Procurement
                  Organizations and hospitals. Educational apps are also
                  available and you can try out a module to help manage red cell
                  use in the inpatient setting. Check out our Products page for
                  more information.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default History