import React from 'react'
import { Link } from "react-router-dom";
import PageTitle from '../../../components/pageTitle/PageTitle';
import SEO from '../../../components/seo/SEO';
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";
import { ButtonContainer, ButtonWrapper } from "./DigitalInternEducator.style";

const DigitalInternEducator = () => {
  return (
    <div style={{minHeight:"80vh"}}>
      <PageTitle title={"Digital Intern Educator Applications"} breadcrumb={
        [
          {url:"/solutions",desc:"Solutions"},
          {url:"/solutions/digital-intern-educator",desc:"Digital Intern Educator Applications"},
        ]
      }/>
    <SEO
    title={"Digital Intern Educator Applications"}
    description={`The Digital Intern® Educator Applications provide access to select
    Digital Intern® modules through the app store for educational
    purposes. Use these apps while you are in school, training, or just
    to brush up on your skills. There is no integration with your
    medical record system when using these applications and they are not
    intended for use with patients. They are only for educational use by
    medical professionals or medical professionals in training.`}
    />
    <SolutionsNav/>
    <div className="container mt-5">
      <div className="row text-center justify-content-center mb-2">
        <div className="col-lg-6">
          The Digital Intern® Educator Applications provide access to select
          Digital Intern® modules through the app store for educational
          purposes. Use these apps while you are in school, training, or just
          to brush up on your skills. There is no integration with your
          medical record system when using these applications and they are not
          intended for use with patients. They are only for educational use by
          medical professionals or medical professionals in training.
        </div>
      </div>
      <div className="row text-center justify-content-center mb-4">
        <div className="col-lg-6 mt-2">
          <ButtonContainer>
            <a
                href="https://itunes.apple.com/us/app/digital-intern-insulin-ed/id1418138604?ls=1&amp;mt=8"
                target="_blank"
                className="market-btn apple-btn"
                rel="noopener noreferrer"
                role="button"
              >
                <span className="market-button-subtitle">
                  Digital Intern® Insulin Educator
                </span>
                <span className="market-button-title">App Store</span>
              </a>
            <a href="#" className="market-btn x">
            <span className="market-button-subtitle">
            Digital Intern® Heparin Educator
                </span>
                <span className="market-button-subtitle">
            Coming Soon!
                </span>
            
            </a>
          </ButtonContainer>
        </div>
      </div>
      <div className="row text-center justify-content-center mb-2">
        <div className="col-lg-6">
        If you like the Digital Intern® Educator and want similar functionality integrated into your workflow through your electronic medical record, <a href={"/contact"}>contact us</a> with information about your organization!
        </div>
      </div>
    </div>
  </div>
  )
}

export default DigitalInternEducator