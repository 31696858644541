import styled from "styled-components";
import { UnderLinedText } from "../../../components/underLinedText/UnderLinedText.style";

export const TeamTitle = styled(UnderLinedText)`
  position: relative;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 60px;
`;


