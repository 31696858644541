import React from "react";

import "./BlockQuoteItem.css"
import quote from './images/right-quote-sign.png';

const BlockQuoteItem = ({ text }) => {
  return (
    <div className="blockquoteitemcard col-md-4">
      <div className="blockquoteitemcard-body">
        {text && (
          <blockquote className=" ">
            <img className="quoteimg" src={quote}  />
            <p className="m-0 text-muted">{text}</p>
          </blockquote>
        )}
      </div>
    </div>
  );
};

export default BlockQuoteItem;
