import React from "react";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import Module from "../../../components/module/Module";
import SEO from "../../../components/seo/SEO";

const Cardiovascular = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
      <SEO
        title="Digital Intern Modules / Cardiovascular"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Atrial and ventricular arrhythmias"}
          text={[`Follow ACLS guidelines to treat arrhythmias`]}
          src={
            "https://drive.google.com/file/d/1BIBzQdiPIoit-s2df0WZiUH-pONlGCZZ/view?usp=sharing"
          }
        />
        <Module
          title={"Hemodynamic management"}
          text={[
            `Uses your physician set goal ranges for heart rate and mean arterial pressure and, optionally, Cardiac Output (or Cardiac Index) and Systemic Vascular Resistance (or Systemic Vascular Resistance Index)`,
          ]}
          src={
            "https://drive.google.com/file/d/13fbIi3dv39yj-gRntrBSnbPzIm5b8Jlu/view?usp=sharing"
          }
        />
        <Module
          title={"Fluid management"}
          text={[
            "Uses your choice of metrics to determine the fluid volume status (Central Venous Pressure together with mean airway pressure, stroke volume variability, or extra-vascular lung water)",
            `Includes diuretics, dialysis, and adjustments for burns and congestive heart failure`,
          ]}
          src={
            "https://drive.google.com/file/d/1CVBQOctNyNTfi_l9HTrnKjYi0TfnDUlC/view?usp=sharing"
          }
        />
        <Module
          title={"Lactate management"}
          text={[
            `Recommends lactate every two hours until result below target`,
            `Interfaces with fluids and cardiovascular modules to help bolster shock management (and sepsis as a subset)`,
          ]}
          src={
            "https://drive.google.com/file/d/1WEweXWtWDX2yRT8QIZ5IykeM7G15omiF/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default Cardiovascular;
