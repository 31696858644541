import React from "react";
import { FooterWrapper, FooterContainer, FooterLink } from "./Footer.style";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper className="container">
        <div className="title">Improving the lives of patients and those who care for them ™</div>
        <div className="legal">
          <p className="copy">
            Copyright © 2022 Integrated Vital Medical Dynamics, LLC. All rights reserved.
          </p>
          <div>
              <FooterLink href={"/about/history"}>
                About iVMD
              </FooterLink>
              <FooterLink href={"/solutions/organ-procurement"}>
              iVMD for Organ Procurement
              </FooterLink>
              <FooterLink href={"/about/team"}>
                  Team
              </FooterLink>
              <FooterLink href={"/contact"}>
              Contact iVMD
              </FooterLink>
              <FooterLink href={"/digital-intern-modules"}>
              Modules
              </FooterLink>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
