import React from 'react'

import universityofWisconsinHospitalandClinicsQualityWeek from "./images/UniversityofWisconsinHospitalandClinicsQualityWeek.jpeg";
import associationofOrganProcurementOrganizationsAnnualMeeting from "./images/AssociationofOrganProcurementOrganizationsAnnualMeeting.jpeg";
import theNeurocriticalCareSocietyAnnualMeeting from "./images/TheNeurocriticalCareSocietyAnnualMeeting.png";
import unitedNetworkforOrganSharingAnnualMeeting from "./images/UnitedNetworkforOrganSharingAnnualMeeting.png";
import uWHealthQualityandSafetyWeek from "./images/UWHealthQualityandSafetyWeek.svg";
import aABBAnnualMeeting from "./images/AABBAnnualMeeting.svg";
import uWSchoolofMedicineandPublicHealth from "./images/UWSchoolofMedicineandPublicHealth.png";
import thrombosisandHemostasisSummitofNorthAmerica from "./images/ThrombosisandHemostasisSummitofNorthAmerica.jpeg";
import abstracXHeartRhythmSociety from "./images/AbstracXHeartRhythmSociety.svg";

import {
  ItemWrapper,
  NewDate,
} from "./NewsPublications.style";
import AboutNav from '../../../components/aboutnav/AboutNav';
import SEO from '../../../components/seo/SEO';

const NewsPublications = () => {
  return (
    <>
    <SEO
        title="News and Publications"
        description="News and Publications"
      />
    <AboutNav/>
    <div className="container mt-5">
        <div className="page-title">
          <h3 className="mt-5 text-center">Presentations</h3>

          <div className="row justify-content-center" style={{ gap: "10px" }}>
            {/*1*/}
            <Item
              date={"August 16-18, 2022."}
              location={"Chicago, IL."}
              img={thrombosisandHemostasisSummitofNorthAmerica}
              title={`Experience with utilizing a heparin dosing calculator to achieve target ACT goals in cardiac electrophysiology procedures.`}
              text={`Thrombosis and Hemostasis Summit of North America`}
              authors={["Rose, AE.", "Medow JE"]}
            />
            {/*2*/}
            <Item
              date={"May 19 - 21, 2023."}
              location={"New Orleans, LA."}
              img={abstracXHeartRhythmSociety}
              title={`Evaluation of an Artificial Intelligence Heparin Dosing Algorithm to Maintain Therapeutic Anticoagulation During Atrial Fibrillation Ablation`}
              text={`AbstracX Heart Rhythm Society`}
              authors={["Kalscheur M", "Martini M", "Medow JE"]}
            />
            <Item
              date={"July 17th, 2018."}
              location={""}
              img={uWSchoolofMedicineandPublicHealth}
              title={`To What Extent Does Feedback and Machine Learning Actually Work?  Doris Duke High School Student Researcher Program.`}
              text={`UW School of Medicine and Public Health`}
              authors={["Ocoko F", "Rehani P", "Medow JE"]}
            />
            {/*3*/}
            <Item
              date={"October 13, 2018"}
              img={aABBAnnualMeeting}
              title={`The Hematology Blood Utilization Calculator; An Adaptive (Learning) Algorithm for management of the Transfusion Dependent Patient`}
              text={`American Association of Blood Banks`}
              authors={[
                "Connor J",
                "Juckett M",
                "Mattison R",
                "Raife T",
                "Medow JE",
              ]}
            />

            {/*4*/}
            <Item
              date={"October 7-10, 2017"}
              location={"San Diego, CA."}
              img={aABBAnnualMeeting}
              title={`Implementation of a Target Based Red Blood Cell Dose Calculator within the Electronic Medical Record Blood Orders Reduces the Use of Non-Surgical Transfusion in an Academic Medical Center`}
              text={`AABB Annual Meeting`}
              authors={[
                "Connor J",
                "Raife T",
                "Ehlenfeldt B",
                "Sipsma K",
                "Medow JE",
              ]}
            />
            {/*5*/}
            <Item
              date={"April 4-6, 2016"}
              location={"Indianapolis, IN."}
              img={unitedNetworkforOrganSharingAnnualMeeting}
              title={`The Digital Intern:  An automated tool designed to improve organ donation rates and reduce management variability`}
              text={`United Network for Organ Sharing Annual Meeting`}
              authors={["Medow JE"]}
            />
            <Item
              date={"October 10-13th 2016."}
              location={""}
              img={uWHealthQualityandSafetyWeek}
              title={`Blood Utilization Calculator (BUC) Implementation.`}
              text={`United Network for Organ Sharing Annual Meeting`}
              authors={[
                "Raife T",
                "Lawler D",
                "Grimes R",
                "Lind J",
                "Sipsma Kristen",
                "Vermeulen L",
                "Ehlenfeldt B",
                "Wilson S",
                "Wilke N",
                "Tilson, Z",
                "Medow JE",
              ]}
            />
            {/*6*/}
            <Item
              date={"June 8-11, 2015"}
              location={"Phoenix, AZ."}
              img={associationofOrganProcurementOrganizationsAnnualMeeting}
              title={`The Digital Intern: Maximizing Practice Efficiency in Organ Donation. `}
              text={`Association of Organ Procurement Organizations Annual Meeting`}
              authors={["Medow JE"]}
            />
            {/*7*/}
            <Item
              date={"September 11-14, 2014"}
              location={"Seattle WA."}
              img={theNeurocriticalCareSocietyAnnualMeeting}
              title={`The Digital Intern: Evaluation of computer controlled algorithms in the neurocritical care unit.`}
              text={`The Neurocritical Care Society Annual Meeting`}
              authors={["Medow JE"]}
            />
            <Item
              date={"October 8th 2013."}
              location={""}
              img={universityofWisconsinHospitalandClinicsQualityWeek}
              title={`Optimizing Comfort in Critically Ill End of Life Situations.`}
              text={`University of Wisconsin Hospital and Clinics Quality Week.`}
              authors={[
                "Schaefer K",
                "Krupp A",
                "Tanke W",
                "Hunter C",
                "Ehlenbach W",
                "Medow JE",
              ]}
            />
            <Item
              date={"October 8th 2013."}
              location={""}
              img={universityofWisconsinHospitalandClinicsQualityWeek}
              title={`The Digital Intern Project:  Using the Electronic Medical Record to Maximize Practice Efficiency in Organ Donation.`}
              text={`University of Wisconsin Hospital and Clinics Quality Week.`}
              authors={[
                "Kundinger T",
                "Frey T",
                "Breihan P",
                "Nickele C",
                "Hunter C",
                "Chesmore P",
                "Tanke W",
                "Medow JE",
              ]}
            />
            <Item
              date={"October 12 - 17th 2013."}
              location={""}
              img={universityofWisconsinHospitalandClinicsQualityWeek}
              title={`The Digital Intern: Evaluation of computer controlled patient management.`}
              text={`University of Wisconsin Hospital and Clinics Quality Week.`}
              authors={[
                "Medow JE",
                "Hamilton S",
                "Hunter C",
                "Kundinger T",
                "Frey T",
                "Raife T",
              ]}
            />
          </div>

          <h3 className="mt-5 text-center">Journal Publications</h3>

          <div className="row justify-content-center" style={{ gap: "10px" }}>
            <Item
              url={
                "https://www.cvdigitalhealthjournal.com/article/S2666-6936(23)00074-9/fulltext"
              }
              date={"2023 Nov 14."}
              location={"PMID: 38222102"}
              title={`Evaluation of an adaptive, rule-based dosing algorithm to maintain therapeutic anticoagulation during atrial fibrillation ablation`}
              text={`Cardiovascular Digital Health Journal, Volume 4, Issue 6, 173 - 182. doi:10.1016/j.cvdhj.2023.11.001. Epub`}
              authors={["Kalscheur MM", "Martini MR", "Mahnke M", "Kipp RT", "Wright JM", "Medow JE"]}
            />
            <Item
              url={
                "https://onlinelibrary.wiley.com/doi/epdf/10.1002/ajh.26841"
              }
              date={"2023 Feb 1."}
              location={""}
              title={`Experience With Utilizing a Heparin Dosing Calculator to Achieve Target ACT Goals in Cardiac Electrophysiology Procedures`}
              text={`American Journal of Hematology. 2023 Feb;98(S1):E90-E91. doi: 10.1002/ajh.26841.  Epub `}
              authors={["Rose AE", "Oie M", "Mahnke M", "Kalscheur MM", "Medow JE"]}
            />
            <Item
              url={
                "https://www.sciencedirect.com/science/article/abs/pii/S000368702200031X"
              }
              date={"2022 Feb 8."}
              location={""}
              title={`Effect of risk, expectancy, and trust on clinicians' intent to use an artificial intelligence system -- Blood Utilization Calculator`}
              text={`Appl Ergon 2022 May;101:103708. doi: 10.1016/j.apergo.2022.103708.  Epub `}
              authors={["Choudhury A", "Asan O", "Medow JE"]}
            />
            <Item
              url={"https://onlinelibrary.wiley.com/doi/abs/10.1111/trf.15962"}
              date={"July 23rd, 2020"}
              title={`Electronic Clinical Decision Support to Facilitate a Change in Clinical Practice: Small Details Can Make or Break Success`}
              text={`Transfusion Vol 60 Issue 9 pp 1970-1976.`}
              authors={[
                "Connor J",
                "Raife T",
                "Ehlenfeldt BD",
                "Rose A",
                "Medow JE",
              ]}
            />
            <Item
              url={"https://onlinelibrary.wiley.com/doi/abs/10.1111/trf.14637"}
              date={"2018 May 1."}
              location={"PMID: 29717482"}
              title={`The Blood Utilization Calculator (BUC), a Target-Based Electronic Decision Support Algorithm, Increases the Use of Single Unit Transfusions in a Large Academic Medical Center`}
              text={`Transfusion. 2018 Jul;58(7):1689-1696. doi: 10.1111/trf.14637. Epub`}
              authors={[
                "Connor J",
                "Raife T",
                "Ehlenfeldt BD",
                "Sipsma K",
                "Medow JE",
              ]}
            />

            <Item
              url={"https://onlinelibrary.wiley.com/doi/abs/10.1111/trf.14424"}
              date={"2017 Dec 1."}
              location={"PMID: 29194652"}
              title={`Outcomes of Red Blood Cell Transfusions Prescribed in Organ Donors by the Digital Intern, an Electronic Decision Support Algorithm`}
              text={`Transfusion. 2018 Feb;58(2):366-371. doi: 10.1111/trf.14424. Epub `}
              authors={["Connor J", "Raife T", "Medow JE"]}
            />

            <Item
              url={"https://onlinelibrary.wiley.com/doi/full/10.1111/trf.14066"}
              date={"June 2017"}
              location={"PMID 28266049"}
              title={`Standardization of Transfusion Practice in Organ Donors Using the Digital Intern, an Electronic Decision Support Algorithm`}
              text={`Transfusion 57(6): 1369–1375`}
              authors={[
                "Connor J",
                "Cunningham A",
                "Raife T",
                "Rose W",
                "Medow JE",
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const Item = ({ date, location, img, title, text, authors, url }) => {
  return (
    <ItemWrapper className="col-lg-3">
      {img ? <img height={"35px"} src={img} alt="" /> : null}

      <div className="post-item-description">
        <NewDate>
          <span className="date">{date}</span>
          <span className="type">{location}</span>
        </NewDate>
        {url ? (
          <a href={url} target="_blank">
            <p className="bigtitle clickable">{title}</p>
          </a>
        ) : (
          <p className="bigtitle">{title}</p>
        )}
        <p className="smalltitle">{text}</p>
        <div className="names">
          {authors.map((item) => (
            <span>{item == "Medow JE" ? <strong>Medow JE</strong> : item}</span>
          ))}
        </div>
      </div>
    </ItemWrapper>
  );
};

export default NewsPublications