import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);

  
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerClassName, setheaderClassName] = useState("light submenu-light");
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    if (scrollPosition > 200 && headerClassName == "light submenu-light") {
      setheaderClassName("light submenu-light header-sticky");

      setTimeout(() => {
        setheaderClassName("light submenu-light header-sticky sticky-active");
      }, 800);
    } else if (scrollPosition < 200) {
      setheaderClassName("light submenu-light");
    }
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={toggle ? "mainMenu-open" : ""}>
      <header
        id="header"
        data-transparent={
          pathname == "/" ? (toggle ? "false" : "true") : "false"
        }
        data-fullwidth="true"
        className={headerClassName}
      >
        <div className="header-inner">
          <div className="container">
            <div id="logo">
              <a href="/">
                <span className="logo-default">
                  <img
                    src="/logo.png"
                    alt=""
                    style={{ width: "200px", borderRadius: "15px" }}
                  />
                </span>
                <span className="logo-dark">
                  <img
                    src="/logowhite.png"
                    alt=""
                    style={{ width: "200px", borderRadius: "15px" }}
                  />
                </span>
              </a>
            </div>

            <div id="mainMenu-trigger" onClick={() => setToggle(!toggle)}>
              <a
                className={
                  toggle ? "lines-button x toggle-active" : "lines-button x"
                }
              >
                <span className="lines"></span>
              </a>
            </div>

            <div id="mainMenu" className={toggle ? "menu-animate" : ""}>
              <div className="container">
                <nav>
                  <ul>
                    <li>
                      <a href="/" id="home">
                        Home
                      </a>
                    </li>
                    <li className="dropdown">
                      <a href="/about" id="about">
                        About
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="/about/digitalintern">Digital Intern</a>
                        </li>
                        <li>
                          <a href="/about/history">History</a>
                        </li>
                        <li>
                          <a href="/about/team">Team</a>
                        </li>
                        <li>
                          <a href="/about/news-publications">News and Publications</a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a href="/solutions" id="solutions">
                        Solutions
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="/solutions">Overview</a>
                        </li>
                        <li>
                          <a href="/solutions/blood-use-management">
                            Blood Product Utilization
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/organ-procurement">
                            iVMD for Organ Procurement
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/therapeutic-duplication">
                            Therapeutic Duplication Solutions
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/supportive-care">
                            Supportive Care
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/catalog">Catalog</a>
                        </li>

                        <li>
                          <a href="/solutions/digital-intern-educator">
                            Digital Intern Educator Applications
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/digital-intern-modules" id="modules">
                        Modules
                      </a>
                    </li>
                    <li>
                      <a href="/demo-playground">Demo / Playground</a>
                    </li>
                    <li>
                      <a href="/frequently-asked-questions" id="faq">
                        Frequently Asked Questions
                      </a>
                    </li>

                    <li>
                      <a href="/contact" id="contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
