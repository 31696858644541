import styled from "styled-components";

export const MemberName = styled.div`
    color: #06c;
    font-size: 16px;
    line-height: 1.21053;
    font-weight: 400;
    letter-spacing: .012em;
    padding-bottom: 10px;
`
export const MemberJob = styled.div`
color: #2b8d12;
font-size: 14px;
line-height: 1.21053;
font-weight: 400;
letter-spacing: -0.032em;
padding-bottom: 5px;
`
export const MemberDesc = styled.p`
font-size: 11px;
line-height: 1.31053;
font-weight: 400;
letter-spacing: -.021em;
`

export const MemberWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
    position:relative;
    .expand{
        width:10px;
        height:10px;
        margin: auto;
        display: block;
    }
    .fadex{
        position: absolute;
        bottom: 10px;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, 
            rgba(255, 255, 255, 0), 
            rgba(255, 255, 255, 0.9)
        100%);
    }
    ${({expanded})=>expanded?`
        .fadex{
            display: none;
        }
        .expand{
            display: none;
        }
    `:""}
    @media only screen and (max-width: 992px){
        .fadex{
            display: none;
        }
        .expand{
            display: none;
        }
    }
`

export const MemberInfoWrapper = styled.div`
    padding-left: 10px;
    
    max-height: 140px;
    overflow:hidden;
    
    
    ${({expanded})=>expanded?`
        max-height: none;
    `:""}
    @media only screen and (max-width: 992px){
        max-width: none;
    }
`
