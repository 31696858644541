import React from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "./PageTitle.style";

const PageTitle = ({ title, breadcrumb,title2 }) => {
  return (
    <Wrapper>
      <section id="page-title">
        <div className="container">
          <div className="page-title">
            <h1 style={{marginBottom:"0px"}}>{title}</h1>
            {title2?(<h1 style={{marginTop:"10px"}}>{title2}</h1>):""}
          </div>
          <div className="breadcrumb">
            <ul>
              {breadcrumb.map((item) => {
                return (
                  <li key={Math.random() * 1000}>
                    <a href={item["url"]}>{item["desc"]}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default PageTitle;
