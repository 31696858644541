import React from "react";
import Module from "../../../components/module/Module";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import SEO from "../../../components/seo/SEO";

const NeuroCns = () => {
  return (
    <div style={{minHeight:"80vh"}}>
      <SEO
        title="Digital Intern Modules / Neuro Cns"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Intracranial pressure management"}
          text={[
            `Takes control of sodium, sedation, pulmonary, and barbiturate coma algorithms as well as making other suggestions to help optimize ICP`,
          ]}
          src={
            "https://drive.google.com/file/d/1642uBudLqwHrFQoYluuMhmGqM5Ugf7Nq/view?usp=sharing"
          }
        />
        <Module
          title={"Pentobarbital coma management"}
          text={[
            `Adjusts bolus and infusion rate to optimize long-term use while preventing toxicity as much as possible`,
            `Uses EEG and multiple labs to address liver and bone marrow function`,
            `Interfaces with multiple modules particularly cardiovascular, blood product, fluid, and electrolyte modules`,
          ]}
          src={
            "https://drive.google.com/file/d/1MtGbhy6XqnG_IxJF-CqL0IXIC72ya8HE/view?usp=share_link"
          }
        />
        <Module
          title={"Temperature management"}
          text={[
            `Uses various methods and timings to help control temperature and considers renal and liver function for safety concerns.`,
          ]}
          src={
            "https://drive.google.com/file/d/1O059EnmB6bBnD2GMxAirXbDYNfdTlGOx/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default NeuroCns;
