import React from "react";
import SEO from "../../../components/seo/SEO";
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";

import Tv from "../../../components/tv/Tv";

const Overview = () => {
  return (
    <>
    <SEO
        title={"Solutions"}
        description={`The Digital Intern® is designed to help streamline medical care by
        handling the tasks a physician might ordinarily hand off to a
        medical intern. Just like a human intern, the Digital Intern®
        needs the guidance of the medical provider and will notify that
        provider if it encounters a situation it can’t handle. This
        guidance is provided through a series of preferences and
        parameters that the provider selects for each patient when they
        initiate the use of the Digital Intern®. Unlike human interns, the
        Digital Intern®’s recommendations follow algorithms that make them
        reproducible yet tailored to the needs of the individual patient.
        As the patient responds to treatments or medications, that new
        clinical data informs the Digital Intern®’s next steps.`}
      />
      <SolutionsNav />
      <div>
        <div className="container mt-5">
          <div className="row mb-lg-6 mb-4">
            <div className="col-lg-12">
              The Digital Intern® is designed to help streamline medical care by
              handling the tasks a physician might ordinarily hand off to a
              medical intern. Just like a human intern, the Digital Intern®
              needs the guidance of the medical provider and will notify that
              provider if it encounters a situation it can’t handle. This
              guidance is provided through a series of preferences and
              parameters that the provider selects for each patient when they
              initiate the use of the Digital Intern®. Unlike human interns, the
              Digital Intern®’s recommendations follow algorithms that make them
              reproducible yet tailored to the needs of the individual patient.
              As the patient responds to treatments or medications, that new
              clinical data informs the Digital Intern®’s next steps.
              <br />
              <br />
              Learn more about the Digital Intern® concept:
            </div>
          </div>
        </div>
        <div className="container" style={{ marginBottom: "30px" }}>
          <Tv>
            <div style={{ padding: "55% 0 10px 10px", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/764061808?h=226d74a35a&amp;portrait=0&amp;byline=0&amp;title=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                title="DI Quick Introduction Video 1 (1).mp4"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Tv>
        </div>
        <div className="container">
          <Tv>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/XZontpIZeys"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Tv>
        </div>

        
       
      </div>
    </>
  );
};

export default Overview;
