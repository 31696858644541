import styled from "styled-components";

export const Wrapper = styled.section`
/* ----------------------------------------------------------------------
    Page title
    -------------------------------------------------------------------------*/
    #page-title { clear: both; padding: 80px 0; background-color: #fbfbfb; position: relative; /* page header left*/ /* page header right*/ }

    #page-title .page-title { text-align: center; padding: 0; }

    #page-title .page-title > h1 { font-family: "Poppins", sans-serif; font-size: 2.5rem; line-height: 1.2; font-weight: 300; margin-bottom: 1rem; }

    #page-title .page-title > span { font-weight: 400; font-size: 14px; }

    #page-title .breadcrumb { text-align: center; margin-bottom: 0; padding: 0; }

    #page-title.page-title-left .page-title { float: left; text-align: left; clear: both; }

    #page-title.page-title-left .breadcrumb { float: left; text-align: left; display: block; }

    #page-title.page-title-right .page-title { float: right; text-align: right; clear: both; }

    #page-title.page-title-right .breadcrumb { float: right; text-align: right; }

    #page-title[data-bg-parallax] .page-title > h1 { color: #fff; }

    #page-title[data-bg-parallax] .page-title > span { color: #fff; }

    #page-title[data-bg-parallax] .breadcrumb ul li + li:before { color: #fff; }

    #page-title[data-bg-parallax] .breadcrumb ul li a { color: #fff; }

    #page-title[data-bg-parallax], #page-title[data-bg-video] { background-color: #1e2022; }

    #header[data-transparent="true"] + #page-title { top: -120px; margin-bottom: -124px; position: relative; padding: 220px 0 160px 0; }

    .dark #page-title, #page-title.dark { background-color: #1e2022; }

    .dark #page-title .page-title > h1, #page-title.dark .page-title > h1 { color: #1e2022; }

    .dark #page-title .page-title > span, #page-title.dark .page-title > span { color: #1e2022; }

    .dark #page-title .breadcrumb ul li + li:before, #page-title.dark .breadcrumb ul li + li:before { color: #1e2022; }

    .dark #page-title .breadcrumb ul li a, #page-title.dark .breadcrumb ul li a { color: #1e2022; }

    @media (max-width: 1091.98px) { #page-title .page-title > h1 { font-size: 26px; line-height: 34px; }
    #header[data-transparent="true"] + #page-title { top: -120px; margin-bottom: -124px; padding: 160px 0 80px 0; } }
    @media (max-width: 531.98px){
        #page-title .page-title > h1 { font-size: 20px; line-height: 29px; }
        #page-title{
            padding: 40px 0;
        }
    }
    
    /* ----------------------------------------------------------------------
    Breadcrumbs
    -------------------------------------------------------------------------*/
    .breadcrumb { background-color: transparent; font-size: 12px; font-weight: 400; letter-spacing: 0.5px; margin-bottom: 10px; display: block; }

    .breadcrumb ul, .breadcrumb ol { display: inline; margin: 0; padding: 0; }

    .breadcrumb ul .breadcrumb-item, .breadcrumb ul li, .breadcrumb ol .breadcrumb-item, .breadcrumb ol li { display: inline; position: relative; opacity: .8; }

    .breadcrumb ul .breadcrumb-item + li:before, .breadcrumb ul li + li:before, .breadcrumb ol .breadcrumb-item + li:before, .breadcrumb ol li + li:before { content: "/"; font-family: "inspiro-icons"; margin: 0 5px; background-color: transparent; }

    .breadcrumb ul .breadcrumb-item a, .breadcrumb ul li a, .breadcrumb ol .breadcrumb-item a, .breadcrumb ol li a { color: #3c4043; }

    .breadcrumb ul .breadcrumb-item:hover, .breadcrumb ul .breadcrumb-item.active, .breadcrumb ul .breadcrumb-item:last-child, .breadcrumb ul li:hover, .breadcrumb ul li.active, .breadcrumb ul li:last-child, .breadcrumb ol .breadcrumb-item:hover, .breadcrumb ol .breadcrumb-item.active, .breadcrumb ol .breadcrumb-item:last-child, .breadcrumb ol li:hover, .breadcrumb ol li.active, .breadcrumb ol li:last-child { opacity: 1; }

`
