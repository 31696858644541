import React from 'react'
import { LocalNavContainer, NavItemWrapper } from './SolutionsNav.style'

import catalog from "./statics/catalogblue.png";
import blood from "./statics/bloodblue.png";
import organ from "./statics/organblue.png";
import educator from "./statics/educatorblue.png";

import supportive from "./statics/supportiveblue.png";
import therapeutic from "./statics/therapeuticblue.png";

const SolutionsNav = () => {
  return (
    <>
    <LocalNavContainer>
        <NavItemWrapper href={"/solutions/blood-use-management"}>
          <img src={blood} alt="" />
          <p>Blood Product Utilization</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/solutions/organ-procurement"}>
          <img src={organ} alt="" />
          <p>Organ Procurement</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/solutions/therapeutic-duplication"}>
          <img src={therapeutic} alt="" />
          <p>Therapeutic Duplication Solutions</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/solutions/supportive-care"}>
          <img src={supportive} alt="" />
          <p>Supportive Care</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/solutions/catalog"}>
          <img src={catalog} alt="" />
          <p>Catalog</p>
        </NavItemWrapper>
        <NavItemWrapper href={"/solutions/digital-intern-educator"}>
          <img src={educator} alt="" />
          <p>Educator Applications</p>
        </NavItemWrapper>
      </LocalNavContainer>
    </>
  )
}

export default SolutionsNav