import React from "react";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import Module from "../../../components/module/Module";
import Tv from "../../../components/tv/Tv";
import SEO from "../../../components/seo/SEO";

const Blood = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
      <SEO
        title="Digital Intern Modules / Blood"
        description="Digital Intern Modules."
      />
      <ModulesNav />

      <ModulesWrapper>
        <Module
          title={"Packed Red Cell Use"}
          text={[
            `Hemoglobin management (red cell transfusions including options for OR)`,
          ]}
          src={
            "https://drive.google.com/file/d/1FR7sE1rLNuMIEgt0LGE88AxBn6TLWu70/view?usp=sharing"
          }
        />
        <Module
          title={"Other Blood Product Use"}
          text={[
            `Plasma and INR correction (including PCC and vitamin K when appropriated)`,
            `Platelet management`,
            `Albumin management`,
          ]}
          src={
            "https://drive.google.com/file/d/1bnkHNVvO4wA-7MGsKvBJGBRGphmJebH2/view?usp=sharing"
          }
        />
        <Module
          title={"Reversal of coagulopathy"}
          text={[
            `Follows recommendations for all known coagulopathy reversal strategies, labs, and half lives not only of the reversal agents but of the process causing coagulopathy where present`,
          ]}
          src={
            "https://drive.google.com/file/d/1MxFG3NZLIaSjYvWBVanmSGUjc5QWBDjz/view?usp=sharing"
          }
        />
        <Module
          title={"Anti-coagulation"}
          text={[
            `Includes options for atrial fibrilation, heart valve, deep vein thrombosis/ venous thromboembolism and heparin-induced thrombocytopenia`,
          ]}
          src={
            "https://drive.google.com/file/d/1E3faZmvh8JDJSZPspD-DxasU1vJQtJZ8/view?usp=sharing"
          }
        />
      </ModulesWrapper>
      <div className="container my-5 d-flex justify-content-center">
        <Tv>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/6RD5RsEJRGo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Tv>
      </div>
    </div>
  );
};

export default Blood;
