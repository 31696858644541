import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Roboto', sans-serif;
    }
    body{
        margin: 0;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        text-transform: none;
        line-height: 1.7;
        letter-spacing: .012em;
        color: #3c4043;
        font-family: 'Roboto', sans-serif;
    }
    p {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        text-transform: none;
        line-height: 1.7;
        letter-spacing: .012em;
        color: #3c4043;
        font-family: 'Roboto', sans-serif;
    }
    @media (max-width: 1091.98px){
    .container {
        padding: 0 30px;
    }
    }
`;
export default GlobalStyle;