import React, { useState } from "react";
import SEO from "../../../components/seo/SEO";

import CoolButton from "../../../components/coolButton/CoolButton";
import ScrollCatch from "../../../components/scrollCatch/ScrollCatch";
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";

import {
  BottomAccordionContainer,
  BottomButtonContainer,
  BottomLineMask,
  ButtonContent,
  ButtonWrapper,
  CenterBottomTextContainer,
  CenterButtonContainer,
  ChartContainer,
  ChartContainerWrapper,
  ChartMask,
  GradientButton,
  HeaderContainer,
  StartButtonContainer,
  StartCenterTextContainer,
} from "./BloodUseManagement.style";

import bloodChart from "./blood_chart.png";
import line from "./line.png";
import PageTitle from "../../../components/pageTitle/PageTitle";
import MyAccordion from "../../../components/accordion/MyAccordion";

const BloodUseManagement = () => {
  const [p, setP] = useState(0);
  return (
    <>
      <SEO
        title="Blood Product Utilization"
        description="The Digital Intern® includes modules to manage the use of blood
        products throughout the hospital. The Digital Intern® requires
        interfaces with your electronic medical record to obtain the patient
        information required to make recommendations for the use blood
        products and related labs. These recommendations can be sent back to
        your electronic medical record software to be displayed within your
        existing workflow. The following diagram is one example of how this
        interface can work."
      />
      <PageTitle
        title={"Blood Product Utilization"}
        breadcrumb={[
          { url: "/solutions", desc: "Solutions" },
          {
            url: "/solutions/blood-use-management",
            desc: "Blood Product Utilization",
          },
        ]}
      />
      <SolutionsNav />
      <div className="container mt-5">
        <div className="row text-center justify-content-center mb-2">
          <div className="col-lg-6">
            The Digital Intern® includes modules to manage the use of blood
            products throughout the hospital. The Digital Intern® requires
            interfaces with your electronic medical record to obtain the patient
            information required to make recommendations for the use blood
            products and related labs. These recommendations can be sent back to
            your electronic medical record software to be displayed within your
            existing workflow. The following diagram is one example of how this
            interface can work.
          </div>
        </div>
        <div className="row text-center justify-content-center mb-4">
          <div className="col-lg-6 mt-2">
            <GradientButton>Scroll Down To See Diagram</GradientButton>
         
          </div>
        </div>
      </div>
      <ScrollCatch
        getPercentageOnScroll={(per) => {
          setP(per);
        }}
        animationLength={280}
        delay={(window.innerHeight * 10) / 100}
      >
        <div style={{ height: "300vh" }}>
          <ChartContainerWrapper>
            <ChartContainer>
              <StartButtonContainer>
                <ButtonWrapper>
                  <CoolButton
                    p={(() => {
                      if (p < 0.01) {
                        return 0;
                      } else if (p > 0.01 && p < 0.201) {
                        return (p - 0.01) * 5;
                      } else {
                        return 1;
                      }
                    })()}
                    color={"#f56772"}
                  />
                  <ButtonContent>
                    <p>Nurse charts information</p>
                  </ButtonContent>
                </ButtonWrapper>
                <ButtonWrapper>
                  <CoolButton
                    p={(() => {
                      if (p < 0.01) {
                        return 0;
                      } else if (p > 0.01 && p < 0.201) {
                        return (p - 0.01) * 5;
                      } else {
                        return 1;
                      }
                    })()}
                    color={"#9e77cf"}
                  />
                  <ButtonContent>
                    <p>Lab Interface</p>
                  </ButtonContent>
                </ButtonWrapper>
                <ButtonWrapper>
                  <CoolButton
                    p={(() => {
                      if (p < 0.01) {
                        return 0;
                      } else if (p > 0.01 && p < 0.201) {
                        return (p - 0.01) * 5;
                      } else {
                        return 1;
                      }
                    })()}
                    color={"#7280ff"}
                  />
                  <ButtonContent>
                    <p>Patient monitors</p>
                  </ButtonContent>
                </ButtonWrapper>
              </StartButtonContainer>
              {(() => {
                if (p < 0.3) {
                  return (
                    <StartCenterTextContainer style={{ opacity: "0" }}>
                      <p>Data</p>
                      <p>Data</p>
                      <p>Data</p>
                    </StartCenterTextContainer>
                  );
                } else if (p >= 0.3 && p < 0.32) {
                  return (
                    <StartCenterTextContainer
                      style={{ opacity: `${(p - 0.3) * 50}` }}
                    >
                      <p>Data</p>
                      <p>Data</p>
                      <p>Data</p>
                    </StartCenterTextContainer>
                  );
                } else {
                  return (
                    <StartCenterTextContainer style={{ opacity: "1" }}>
                      <p>Data</p>
                      <p>Data</p>
                      <p>Data</p>
                    </StartCenterTextContainer>
                  );
                }
              })()}

              <CenterButtonContainer>
                <ButtonWrapper style={{ width: "200px" }}>
                  <CoolButton
                    p={(() => {
                      if (p < 0.4) {
                        return 0;
                      } else if (p > 0.4 && p < 0.6) {
                        return (p - 0.4) * 5;
                      } else {
                        return 1;
                      }
                    })()}
                  />
                  {(() => {
                    if (p < 0.4) {
                      return (
                        <ButtonContent style={{ opacity: "0" }}>
                          <p>Electronic Medical Record System</p>
                        </ButtonContent>
                      );
                    } else if (p > 0.4 && p < 0.42) {
                      return (
                        <ButtonContent style={{ opacity: `${(p - 0.4) * 50}` }}>
                          <p>Electronic Medical Record System</p>
                        </ButtonContent>
                      );
                    } else {
                      return (
                        <ButtonContent style={{ opacity: "1" }}>
                          <p>Electronic Medical Record System</p>
                        </ButtonContent>
                      );
                    }
                  })()}
                </ButtonWrapper>
              </CenterButtonContainer>
              {(() => {
                if (p < 0.6) {
                  return (
                    <CenterBottomTextContainer>
                      <p style={{ opacity: "0" }}>Recommended orders</p>
                      <p style={{ opacity: "0" }}>
                        Blood order information, relecant patient data
                      </p>
                    </CenterBottomTextContainer>
                  );
                } else if (p >= 0.6 && p < 0.62) {
                  return (
                    <CenterBottomTextContainer>
                      <p style={{ opacity: "0" }}>Recommended orders</p>
                      <p style={{ opacity: `${(p - 0.6) * 50}` }}>
                        Blood order information, relecant patient data
                      </p>
                    </CenterBottomTextContainer>
                  );
                } else if (p >= 0.62 && p < 0.85) {
                  return (
                    <CenterBottomTextContainer>
                      <p style={{ opacity: "0" }}>Recommended orders</p>
                      <p style={{ opacity: `1` }}>
                        Blood order information, relecant patient data
                      </p>
                    </CenterBottomTextContainer>
                  );
                } else if (p >= 0.85 && p < 0.87) {
                  return (
                    <CenterBottomTextContainer>
                      <p style={{ opacity: `${(p - 0.65) * 50}` }}>
                        Recommended orders
                      </p>
                      <p style={{ opacity: `1` }}>
                        Blood order information, relecant patient data
                      </p>
                    </CenterBottomTextContainer>
                  );
                } else {
                  return (
                    <CenterBottomTextContainer style={{ opacity: `1` }}>
                      <p style={{ opacity: "1" }}>Recommended orders</p>
                      <p style={{ opacity: "1" }}>
                        Blood order information, relecant patient data
                      </p>
                    </CenterBottomTextContainer>
                  );
                }
              })()}
              <BottomButtonContainer>
                <ButtonWrapper>
                  <CoolButton
                    p={(() => {
                      if (p < 0.7) {
                        return 0;
                      } else if (p > 0.7 && p < 0.8) {
                        return (p - 0.7) * 10;
                      } else {
                        return 1;
                      }
                    })()}
                  />
                  {(() => {
                    if (p < 0.7) {
                      return (
                        <ButtonContent style={{ opacity: "0" }}>
                          <p>Digital Intern Server</p>
                        </ButtonContent>
                      );
                    } else if (p > 0.7 && p < 0.72) {
                      return (
                        <ButtonContent style={{ opacity: `${(p - 0.7) * 50}` }}>
                          <p>Digital Intern Server</p>
                        </ButtonContent>
                      );
                    } else {
                      return (
                        <ButtonContent style={{ opacity: "1" }}>
                          <p>Digital Intern Server</p>
                        </ButtonContent>
                      );
                    }
                  })()}
                </ButtonWrapper>
              </BottomButtonContainer>
              {(() => {
                if (p < 0.2) {
                  return (
                    <ChartMask style={{ height: "0%" }}>
                      <img src={bloodChart} alt="" />
                    </ChartMask>
                  );
                } else if (p >= 0.2 && p < 0.4) {
                  return (
                    <ChartMask style={{ height: `${(p - 0.2) * 5 * 50}%` }}>
                      <img src={bloodChart} alt="" />
                    </ChartMask>
                  );
                } else if (p >= 0.4 && p < 0.5) {
                  return (
                    <ChartMask style={{ height: `50%` }}>
                      <img src={bloodChart} alt="" />
                    </ChartMask>
                  );
                } else if (p >= 0.5 && p < 0.7) {
                  return (
                    <ChartMask
                      style={{ height: `${(p - 0.5) * 5 * 50 + 50}%` }}
                    >
                      <img src={bloodChart} alt="" />
                    </ChartMask>
                  );
                } else if (p >= 0.7) {
                  return (
                    <ChartMask style={{ height: "100%" }}>
                      <img src={bloodChart} alt="" />
                    </ChartMask>
                  );
                } else {
                  return "";
                }
              })()}
              {(() => {
                if (p < 0.8) {
                  return (
                    <BottomLineMask style={{ height: `0%` }}>
                      <img src={line} alt="" />
                    </BottomLineMask>
                  );
                } else if (p > 0.8 && p < 0.9) {
                  return (
                    <BottomLineMask
                      style={{ height: `${(p - 0.8) * 10 * 50}%` }}
                    >
                      <img src={line} alt="" />
                    </BottomLineMask>
                  );
                } else {
                  return (
                    <BottomLineMask style={{ height: `50%` }}>
                      <img src={line} alt="" />
                    </BottomLineMask>
                  );
                }
              })()}
            </ChartContainer>
          </ChartContainerWrapper>
        </div>
      </ScrollCatch>
      <div className="container">
        <MyAccordion
          content={[
            {
              title: "Inpatient Hemoglobin Management",
              desc: [
                `The Digital Intern®’s Hemoglobin Management module for inpatient
                  use will recommend an appropriate number of units of red blood
                  cells to meet a target. Providers choose the target for their
                  patients. The Digital Intern® will also recommend an appropriate
                  time to re-draw the lab. In the case of a significant drop in
                  hemoglobin, the Digital Intern® may recommend an immediate re-draw
                  to guard against errors (while also recommending starting to give
                  red blood cells if indicated based on the current lab values and
                  the patient’s vital signs).`,
                `The Digital Intern® hemoglobin algorithm has been in use at the
                  UWHC since 2016 and has reduced the units of red blood cells used
                  by the hospital significantly.`,
              ],
            },
            {
              title: "Inpatient INR Correction",
              desc: [
                ` The Digital Intern®’s INR correction module will recommend
                  appropriate steps to reach a target INR chosen by the provider
                  taking into account the indications and time frame for INR
                  reversal. Recommended steps may include vitamin K, PCCs, and
                  transfusion of plasma.`,
                `The Digital Intern® INR correction algorithm has been in use at
                  UWHC since 2017 and has reduced the amount of plasma used by
                  approximately 160 units per month.`,
              ],
            },
            {
              title: "Inpatient Platelet Use",
              desc: [
                `The Digital Intern®’s platelet module will calculate an
                appropriate number of units to transfuse to reach a target
                specified by the provider. The module will use information about
                past transfusions for the same patient to adjust its
                recommendations and determine whether the desired target can be
                reached.`,
              ],
            },
            {
              title: "Operating Room Hemoglobin Management",
              desc: [
                `The Digital Intern® Operating Room module uses the patient’s fluid
                I/O information, initial hemoglobin or hematocrit value, and
                estimated blood loss to recommend appropriate transfusions to keep
                the patient at or above a target hemoglobin or hematocrit level
                set by the provider. It will also recommend post transfusion
                follow-up labs.`,
              ],
            },
            {
              title: "Outpatient Hematology Clinics",
              desc: [
                `The Digital Intern®’s hematology clinic module makes
                recommendations based on a patient’s past transfusions and labs.
                It will become more accurate for an individual patient over time.
                The provider may specify a date for the patient to return to
                clinic and the Digital Intern® will recommend the number of red
                blood cell units that need to be transfused today to keep the
                patient at or above the goal hemoglobin level until that return
                date. Alternately, the provider may specify the number of units to
                be transfused today and the Digital Intern® will recommend a
                return date when it will be expected that the patient will need
                another transfusion.`,
                `This hemoglobin module has been in use at UWHC since 2017 and has
                made accurate predictions for more than 80% of patients.`,
                `We are also working to develop an outpatient platelet module which
                would function similarly and recommend the appropriate number of
                units or return date for platelet transfusions.`,
              ],
            },
            {
              title: "Albumin Stewardship",
              desc: [
                `The Digital Intern® Albumin module recommends the use Albumin when
                appropriate in the inpatient setting.`,
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default BloodUseManagement;
