import React, { useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";

const ScrollCatch = ({
  children,
  getPercentageOnScroll,
  once,
  animationLength,
  delay,
  xxx,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [stop, setStop] = useState(false);
  const [per, setPer] = useState(0);
  const ref = useRef();
  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);
  useEffect(() => {
    getPercentageOnScroll(per);
  }, [per]);
  useEffect(() => {
    if (startPoint != 0) {
      if (scrollY > startPoint && scrollY < endPoint) {
        if (!stop && getPercentageOnScroll) {
          setPer((scrollY - startPoint) / (endPoint - startPoint));
        }
      } else if (scrollY > endPoint && per != 1) {
        setPer(1);
      } else if (scrollY < startPoint && per != 0) {
        if (!once) {
          setPer(0);
        }
      }
      if (once && scrollY > endPoint) {
        setStop(true);
      }
    }
  }, [scrollY,startPoint]);

  return (
    <>
      <Waypoint
        onPositionChange={({ previousPosition, currentPosition }) => {
          const s =
            scrollY +
            ref.current.getBoundingClientRect().top -
            window.innerHeight +
            (delay ? delay : 0);
          setStartPoint(s);
          setEndPoint(
            s +
              (animationLength
                ? (window.innerHeight * animationLength) / 100
                : ref.current.offsetHeight)
          );
          // if(!previousPosition && currentPosition=="inside"){
          //   const s = (scrollY+ref.current.getBoundingClientRect().top) - window.innerHeight + (delay ? delay : 0);
          //   setStartPoint(s);
          //   setEndPoint(
          //     s +
          //       (animationLength
          //         ? (window.innerHeight * animationLength) / 100
          //         : ref.current.offsetHeight)
          //   );
          // }else if(!previousPosition && currentPosition == "above"){
          //   const s = (scrollY+ref.current.getBoundingClientRect().top) - window.innerHeight + (delay ? delay : 0);
          //   setStartPoint(s);
          //   setEndPoint(
          //     s +
          //       (animationLength
          //         ? (window.innerHeight * animationLength) / 100
          //         : ref.current.offsetHeight)
          //   );
          // }
        }}
        onEnter={({ previousPosition, currentPosition }) => {
          // if (previousPosition == "below" && currentPosition == "inside") {
          //   const s = (scrollY+ref.current.getBoundingClientRect().top) - window.innerHeight + (delay ? delay : 0);
          //   console.log("s=>",s)
          //   setStartPoint(s);
          //   setEndPoint(
          //     s +
          //       (animationLength
          //         ? (window.innerHeight * animationLength) / 100
          //         : ref.current.offsetHeight)
          //   );
          // }
        }}
      >
        <div ref={ref}>{children}</div>
      </Waypoint>
    </>
  );
};

export default ScrollCatch;
