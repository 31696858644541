import React, { useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import MyAccordion from "../../components/accordion/MyAccordion";
import BlockQuoteItem from "../../components/blockQuoteItem/BlockQuoteItem";
import SEO from "../../components/seo/SEO";
import Slider from "../../components/slider/Slider";
import { UnderLinedText } from "../../components/underLinedText/UnderLinedText.style";
import "./Home.css";






const Home = () => {
  const [counterStatus, setCounterStatus] = useState(0);
  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  const counterRef1 = useRef();
  const counterRef2 = useRef();
  const counter1Start = ()=>{
    const obj = counterRef1.current;
    animateValue(obj, 0, 7000, 2000);
  }
  const counter2Start = ()=>{
    const obj = counterRef2.current;
    animateValue(obj, 0, 900, 1700);
  }
  useEffect(() => {
    if(counterStatus==1){
      counter1Start();
      counter2Start();
    }
  }, [counterStatus])
  

  return (
    <>
    
      <SEO
        title="Digital Intern"
        description="Digital Intern®. Consistent Approach. Individualized Care."
      />
      <Slider />
  
      <Waypoint  onEnter={() => {
        setCounterStatus(1)
        
      }}>
        <div className="counterwrapper">
          <div ref={counterRef1}
            className={"counter"}
          ></div>
          <div className="counterright">+ Clinical Decisions</div>
          <p></p>
        </div>
      </Waypoint>
      <div className="counterwrapper">
        <div ref={counterRef2}
          className={"counter"}
        ></div>
        <div className="counterright">+ Patients</div>
        <p></p>
      </div>

      <div className="container my-5">
        <div className="col-xl-10 m-auto">
          <p style={{ textAlign: "center" }} className="mb-5 pb-5">
            Today, hospitals and caregivers alike are responsible for an
            enormous number of cost saving, metric-driven initiatives and at the
            same time are expected to provide optimal patient care. The Digital
            Intern® is a new software product designed and developed to aid your
            organization in improving adherence to best clinical practices,
            reducing costs, increasing efficiency and reducing physicians’
            workload.
          </p>
          <UnderLinedText bottom={10} width={150}>
          <p style={{fontSize:"20px"}} className="mt-5">
            Automated Decision-Making for Healthcare
          </p>
          </UnderLinedText>
          
          <p>
          The Digital Intern® is an AI, clinical decision support software platform that delivers meaningful, real-time recommendations and important calculations to providers in hospital and medical transport settings.  Physicians decide patient goals for vital signs, labs, ventilation, comfort, and nutrition and the Digital Intern® engine goes to work, communicating its recommendations to nursing and respiratory therapy staff as a part of their normal EHR workflow.  The Digital Intern® performs like an EICU without the human resources component.  And….. unlike other AI solutions, the Digital Intern® provides supportive care to prevent complications from happening rather than predicting when an adverse event will occur.
          </p>
          <UnderLinedText bottom={10} width={150}>
          <p style={{fontSize:"20px"}} className="mt-5">
          Deployable, Highly Efficient AI Without Needing a Server or Network
          </p>
          </UnderLinedText>
          
          <p>
          Seems impossible?  Not at all!  The Digital Intern Octane® offers the same level of clinical decision support that the EHR deployed version provides and does so entirely on an iPad.  That’s right, should you need to manage a cardiac arrhythmia, elevated ICP, or ARDS in the middle of nowhere, the Digital Intern Octane® will use whatever resources are told that it has available and provide solutions to effectively treat your patient.  A perfect match for medical transport providers who may not have ready access to a critical care physician when one is needed.  And….. when the Digital Intern Octane® reaches a Digital Intern® connected EHR, it will communicate what it learned about the patient’s clinical responses making the handoff smooth and efficient. 
          </p>
          <MyAccordion
            preExpanded={false}
            content={[
              {
                title: "For Physicians",
                desc: [
                  ` When your department is particularly busy, turn to trusted digital staff for an extra hand.`,
                  `Set goals for your patients and let the Digital Intern® help meet them – improve outcome measures without having to monitor every detail.`,
                  `Focus on the issues that truly require your expertise and judgement without constant interruption.`,
                ],
                link: [
                  `Learn more about the kinds of things the Digital Intern® can help you manage -replacehere-.`,
                  `here`,
                  `/digital-intern-modules`,
                ],
                expand: "a",
              },
              {
                title: "For Nurses",
                desc: [
                  ` Spend less time paging physicians and waiting on physician instruction.`,
                  `Provide consistent care in line with best practices.`,
                  `Start, stop, and titrate medications, fluids, electrolytes, blood products, and nutrition without delay.`,
                  `Change ventilator settings and use airway clearance devices when needed to achieve goal gas exchange and pulmonary pressures.`,
                  `Be able to talk with physicians about important care decisions with a clearly explained rationale for clinical recommendations.`
                ],
                expand: "b",
              },
              {
                title: "For Administrators",
                desc: [
                  `Ensure physicians receive the help they need without additional human resources.`,
                  `Optimize capacity management to elicit more high-level physician decisions during busy times without the need to transfer patients out of your institution.`,
                  `Reduce variability in practice.`,
                  `Prioritize lower cost drugs when medically appropriate.`,
                  `Ensure blood products are used efficiently and without waste.`,
                  `Solve problems of therapeutic duplication.`,
                ],
                expand: "c",
              },
            ]}
          />
        </div>
      </div>
      <section className="pt-5 pb-5">
        <div className="container col-xl-8 m-auto">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-3 text-center quotetitle">
                Physician Testimonials
              </h2>
            </div>
            <BlockQuoteItem
              text={
                "Less interruptions, less variability with ACTs, ACTs more frequently at goal"
              }
            />
            <BlockQuoteItem
              text={
                "Fewer distractions during difficult cases, less delay to therapeutic ACT"
              }
            />
            <BlockQuoteItem
              text={
                "More reliably achieving ACTs, less mental burden on operator"
              }
            />
            <div className="col-12 mt-5">
              <h2 className="mb-3 text-center quotetitle">
                Nurse Testimonials
              </h2>
            </div>

            <BlockQuoteItem
              text={
                "More consistency in anticoagulation, more autonomy for nurses, quicker adjustments for ACT’s."
              }
            />
            <BlockQuoteItem
              text={
                "More autonomy in my practice. Less interruptions for the physician."
              }
            />
            <BlockQuoteItem text={"More independence and autonomy."} />
            <BlockQuoteItem text={"Love the nurse autonomy, and consistency"} />
            <BlockQuoteItem text={"Autonomy and fewer interruptions"} />
            <BlockQuoteItem text={""} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
