import React from "react";
import "./MyAccordion.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

const GetText = ({text})=>{
  return(
    <p>
      
      {text.split("***")[0]}
      {
        text.split("***").length > 1 ? <a href={text.split("***")[1].split("&&&")[0]}>{text.split("***")[1].split("&&&")[1]}</a> : ""
      }
      {text.split("***").length > 2 ? text.split("***")[2]:""}
    </p>
  )
}

const MyAccordion = ({content, background,preExpanded}) => {
  return (
    <Accordion preExpanded={preExpanded?["a"]:undefined} allowZeroExpanded>
      {content.map((item, i) => (
        <AccordionItem  className="cardaccordion" style={{background:background}} key={i} uuid={item.expand}>
          <AccordionItemHeading className="card-header">
            <AccordionItemButton>
              <h5 className="mb-0" style={{color:"#06c"}}>
                <button >{item.title}</button>{" "}
              </h5>
            </AccordionItemButton>
          </AccordionItemHeading>
          {/* Accordion Heading */}
          <AccordionItemPanel className="card-body fadeInUp">
            {typeof item.desc == "object"?(
              item.desc.map((text)=><GetText key={Math.random()*1000} text={text}/>)
            ):(<GetText text={item.desc}/>)}
            {
              item.link?(
                <p> {item.link[0].split("-replacehere-")[0]}<a href={item.link[2]}>{item.link[1]}</a> {item.link[0].split("-replacehere-")[1]}</p>
              ):null
            }
            
          </AccordionItemPanel>
          {/* Accordion Body Content */}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default MyAccordion;
