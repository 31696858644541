import React from "react";
import { CatalogWrapper, LiCatalogAlgoritm } from "./Catalog.style";

import vMD051 from "./images/vMD051.png";
import iVMDExecutiveSummaryLong from "./images/iVMDExecutiveSummaryLong.png";
import executiveSummary from "./images/executiveSummary.png";
import briefPitchSheet from "./images/briefPitchSheet.png";

import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";
import PageTitle from "../../../components/pageTitle/PageTitle";
import SEO from "../../../components/seo/SEO";
import PdfCard from "../../../components/pdfCard/PdfCard";
import { PdfCardContainer } from "../../../components/pdfCard/PdfCard.style";

const Catalog = () => {
  const images = [
    vMD051,
    briefPitchSheet,

    executiveSummary,

    iVMDExecutiveSummaryLong,
  ];
  const pdfurls = [
    "https://drive.google.com/file/d/1t1KCy_Y6R0f2EB4_aN-QYUG19RMoswi-/view?usp=sharing",
    "https://drive.google.com/file/d/12pr9IRswELUGRMgCecTi7vnnKRvNR4bC/view",

    "https://drive.google.com/file/d/1HGruaCQwnxE4hGkjtWBH2tx_ugk7Tge3/view?usp=sharing",

    "https://drive.google.com/file/d/1WWJyQ5Ob6g6kxy-Dd4qDNc_bFt30VDAI/view?usp=sharing",
  ];
  const algorithmcatalog = [
    `Invasive Pulmonary (including all ventilator settings, iTime, modes,
      airway clearance, albuterol nebulizer, 3% hypertonic sodium
      nebulizer, nitric oxide, epoprostenol, thick secretions, and
      ipratropium)`,

    "VAP prevention",

    `Non-Invasive Pulmonary (including high flow n/c, BiPAP,
      oxymask, n/c, adjunctive therapies)`,

    "Chest X-ray ordering",

    "Blood gas analysis Anion Gap Osmolar Gap",

    "Hemodynamic / Pressors / Antihypertensives",

    "IV fluids including burn adjustments",

    "Metabolic and Respiratory acidosis algorithm",

    "Lactate Management / Sepsis",

    "Rhabdomyolysis management",

    "Hyperkalemia management",

    "Hypokalemia management",

    "Hypernatremia management",

    "Hyponatremia management",

    "Hypophosphatemia management",

    "Hypocalcemia management",

    "Hypomagnesemia management",

    "Hematocrit/ hemoglobin correction",

    "Albumin correction",

    "Hematocrit/ hemoglobin Standard (inpatient)",

    "Hematocrit/ hemoglobin OR",

    "Outpatient hematocrit/ hemoglobin transfusion scheduler",

    "Platelet calculator",

    "Plasma calculator + INR correction (PCC Vit K)",

    "Transfusion reaction",

    "Pain management",

    "Agitation management",

    "End of Life Care",

    "Heparin Cath Lab",

    "Heparin bolus / infusion for cath lab and CT surgery",

    "Heparin infusion algorithm with defaults for PE, ECMO, etc.",

    "Heparin Induced Thrombocytopenia (recognition and treatment)",

    "GI bleed",

    `ICP Management module (including bed positioning, default max goal,
      EVD management, mannitol, paralytic management, pentobarb coma
      management, sedation, hypertonic sodium, surgical options, and
      temperature management)`,

    "Seizure management algorithm",

    "Bladder management / Foley catheter protocol",

    "Dysautonomia / Neurostorming / Autonomic Dysreflexia",

    "EtOH Withdrawal",

    "Fever management",

    "Adrenal Insufficiency",

    "Enteric Nutrition",

    "Insulin and hypoglycemia",

    "MI management",

    "Arrhythmia management module",

    "Amiodarone for Cardiac Arrhythmias",

    "Magnesium for Cardiac and Eclampsia Patients",

    "Adenosine for Cardiac Arrhythmias",

    "Lidocaine for Cardiac Arrhythmias",

    "Digoxin dosing / Digibind",

    "Procainamide for Cardiac Arrhythmias",

    "Esmolol for Cardiac Arrhythmias",

    "Diltiazem for Cardiac Arrhythmias",

    "Cardioversion for Cardiac Arrhythmias",

    "Metoprolol for Cardiac Arrhythmias",
  ];
  return (
    <CatalogWrapper>
      <PageTitle
        title={"Catalog"}
        breadcrumb={[
          {
            url: "/solutions",
            desc: "Solutions",
          },
          {
            url: "/solutions/catalog",
            desc: "Catalog",
          },
        ]}
      />
      <SolutionsNav />
      <SEO title="Catalog" description="Digital Intern®. Catalog." />
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            {algorithmcatalog.map((item, index) => {
              if (index + 1 < 18) {
                return (
                  <LiCatalogAlgoritm key={Math.random()*10000}>
                    {index + 1 + ". " + item}
                  </LiCatalogAlgoritm>
                );
              }
            })}
          </div>
          <div className="col-md-4">
            {algorithmcatalog.map((item, index) => {
              if (index + 1 >= 18 && index + 1 <= 35) {
                return (
                  <LiCatalogAlgoritm key={Math.random()*10000}>
                    {index + 1 + ". " + item}
                  </LiCatalogAlgoritm>
                );
              }
            })}
          </div>
          <div className="col-md-4">
            {algorithmcatalog.map((item, index) => {
              if (index + 1 > 35 && index + 1 < 60) {
                return (
                  <LiCatalogAlgoritm key={Math.random()*10000}>
                    {index + 1 + ". " + item}
                  </LiCatalogAlgoritm>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        
          <PdfCardContainer>
            {images.map((image, index) => (
              <PdfCard key={Math.random()*10000} url={pdfurls[index]} img={image} colCount={2} />
            ))}
          </PdfCardContainer>
       
      </div>
    </CatalogWrapper>
  );
};

export default Catalog;
