import React from "react";
import Module from "../../../components/module/Module";
import { ModulesWrapper } from "../../../components/module/Module.style";
import ModulesNav from "../../../components/modulesnav/ModulesNav";
import SEO from "../../../components/seo/SEO";

const Renal = () => {
  return (
    <div style={{minHeight:"80vh"}}>
      <SEO
        title="Digital Intern Modules / Renal"
        description="Digital Intern Modules."
      />
      <ModulesNav />
      <ModulesWrapper>
        <Module
          title={"Foley catheter management"}
          text={[
            `Recommends appropriately timed bladder scans and Foley catheter removal as well as when to straight cath patients`,
            `Particularly useful with spinal cord injured patients`,
          ]}
          src={
            "https://drive.google.com/file/d/1oHL0oZWxDllxfYNg3eTPO8IT7DnOt-gc/view?usp=sharing"
          }
        />
        <Module
          title={"Rhabdomyolysis management"}
          text={[
            `Recommends obtaining CK levels and runs diuretics, fluids, and if necessary dialysis`,
            `Interfaces with fluids and electrolyte modules`,
          ]}
          src={
            "https://drive.google.com/file/d/1Ajo963kowy2PQH37DyZ2jmKapv6BJEqd/view?usp=sharing"
          }
        />
      </ModulesWrapper>
    </div>
  );
};

export default Renal;
