import React from "react";
import supportiveImage from "./images/supportiveImage.jpg";

import cOVID19 from "./images/cOVID19.png";
import heparin from "./images/heparin.png";
import insulinInfusionAlgorithmReboot from "./images/insulinInfusionAlgorithmReboot.png";
import otherBlood from "./images/otherBlood.png";
import sepsis from "./images/sepsis.png";
import supportiveCare from "./images/supportiveCare.png";
import vent from "./images/vent.png";
import dIhemoglobinAndGlucose from "./images/dIhemoglobinAndGlucose.png";
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";
import SEO from "../../../components/seo/SEO";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { DisposeOnSmall } from "./SupportiveCare.style";
import { PdfCardContainer } from "../../../components/pdfCard/PdfCard.style";
import { Link } from "react-router-dom";
import PdfCard from "../../../components/pdfCard/PdfCard";

const TextList = ({ text }) => {
  return (
    <p
      style={{
        padding: "3px",
        fontSize: "13px",
        lineHeight: "1.5",
        margin: "0 0 0 10px",
      }}
    >
      <span style={{ padding: "10px", fontSize: "15px" }}>&bull;</span>
      {text}
    </p>
  );
};

const SupportiveCare = () => {
  const images = [
    cOVID19,
    heparin,
    insulinInfusionAlgorithmReboot,
    sepsis,
    supportiveCare,
    vent,
    dIhemoglobinAndGlucose,
    otherBlood,
  ];
  const pdfurls = [
    "https://drive.google.com/file/d/1PCWGgChPJ4Cm2w87dtwH4j0JSNjQZgz9/view?usp=sharing",

    "https://drive.google.com/file/d/1f0SNmOGbLriZnvMUqtJN3vW-MmsFFnaO/view?usp=sharing",
    "https://drive.google.com/file/d/1UP-s1E-3vhdQ7C9fWSUXf8uzTPTz6fgJ/view?usp=sharing",

    "https://drive.google.com/file/d/1ACP0gxtQhhUund2vXBpC9b1-_55FynIb/view?usp=sharing",
    "https://drive.google.com/file/d/1FRVPBxPluseIA8Qc2MOgnJscwRPlXflm/view?usp=sharing",

    "https://drive.google.com/file/d/1HNCSymmB09jhyjwd02INeDg-TdlnNB7p/view?usp=sharing",

    "https://drive.google.com/file/d/1-wh7TZGSEKEMevZWVyBNI5otHuIU02yr/view?usp=sharing",
    "https://drive.google.com/file/d/1ce2A_WlkSDWCPtzGiWiBPSi86tGUdYPQ/view?usp=sharing",
  ];
  const colCount = 2;
  const iterrator = [];
  for (let i = 0; i < images.length; i++) {
    iterrator.push({ colCount, url: pdfurls[i], img: images[i] });
  }
  return (
    <>
      <PageTitle
        title={"The DIGITAL INTERN® and Supportive Care"}
        breadcrumb={[
          { url: "/solutions", desc: "Solutions" },
          {
            url: "/solutions/supportive-care",
            desc: "Supportive Care",
          },
        ]}
      />
      <SEO
        title={"Supportive Care"}
        description={`The Digital Intern can help a medical care team manage supportive
        care needs for patients fighting infections or other serious
        conditions.`}
      />
      <SolutionsNav />
      <div className="container mt-5">
          <div className="row  justify-content-center mb-2">
            <div className="col-lg-8">
              The Digital Intern can help a medical care team manage supportive
              care needs for patients fighting infections or other serious
              conditions. Using labs and vital signs, the Digital Intern makes
              specific recommendations to maintain patient’s heart rate, blood
              pressure, fluid volume, electrolytes, and respiratory function.
              The Digital Intern can help physicians manage more patients by
              eliminating some of the routine decisions they would typically be
              called upon to make. Nurses are also able to respond to changes in
              the patient’s condition immediately with the help of Digital
              Intern recommendations.
            </div>
          </div>

          <div className="row  justify-content-center mb-2">
            <div className="col-lg-8">
              The Digital Intern consists of many different modules and you may
              install these piece-by-piece at your institution, but all of these
              provide recommendations for the supportive care that is critical
              for your patients’ survival and recovery:
              <TextList
                text={
                  "Hemodynamic Management: following blood pressure, heart rate, cardiac output, and systemic vascular resistance to keep these metrics within goal ranges set by the physician"
                }
              />
              <TextList
                text={
                  "Fluid Volume Management: using volume status indicators such as central venous pressure and mean airway pressure, stroke volume"
                }
              />
              <TextList
                text={
                  "Electrolyte Management: calcium, potassium, phosphate, magnesium, and sodium are monitored and recommendations are made to bring these into target ranges as needed"
                }
              />
              <TextList
                text={
                  "Ventilator Management: includes blood gas and lactate labs and functions with any ventilator mode, recommending changes in mode when needed"
                }
              />
              <div style={{ display: "flex", gap: "20px" }}>
                <DisposeOnSmall
                  src={supportiveImage}
                  style={{ width: "200px", margin: "0 0 0 10px" }}
                  alt=""
                />
                <div>
                  <p
                    style={{
                      padding: "3px",
                      fontSize: "13px",
                      lineHeight: "1.9",
                    }}
                  >
                    The Digital Intern does not replace the care team.
                    Physicians, nurses, pharmacists, and respiratory therapists
                    still care for the patient, make decisions about the
                    patient’s care, and determine when to utilize the
                    recommendations provided by the Digital Intern.
                  </p>
                  <p
                    style={{
                      padding: "3px",
                      fontSize: "13px",
                      lineHeight: "1.9",
                    }}
                  >
                    The Digital Intern does not diagnose infections, sepsis, or
                    other conditions. The Digital Intern’s recommendations are
                    designed to help provide supportive care. The provider will
                    still determine the appropriate diagnosis and treat
                    accordingly but the Digital Intern can help keep the patient
                    stable with supportive recommendations throughout that
                    process.
                  </p>
                </div>
              </div>
              <p
                style={{ padding: "3px", fontSize: "13px", lineHeight: "1.5" }}
              >
                <span style={{ padding: "10px", fontSize: "15px" }}>
                  &bull;
                </span>
                <strong>Flexible - </strong> Target ranges for each metric can
                be adjusted by the Provider for each patient
              </p>
              <p
                style={{ padding: "3px", fontSize: "13px", lineHeight: "1.5" }}
              >
                <span style={{ padding: "10px", fontSize: "15px" }}>
                  &bull;
                </span>
                <strong>Consistent - </strong> The Digital Intern’s consistent
                approach reduces variability while still taking into account the
                different needs and goals of individual patients
              </p>
              <a href={"/contact"} style={{ color: "green" }}>
                Contact us
              </a>{" "}
              today for more information about how the Digital Intern can help
              your team succeed.
            </div>
          </div>
        </div>
        <PdfCardContainer>
        {iterrator.map((item) => (
                <PdfCard key={Math.random()*10000} {...item} />
              ))}
        </PdfCardContainer>
    </>
  );
};

export default SupportiveCare;
