import React, { useState } from "react";
import img from "./images/flowchart.png";
import result from "./images/result.jpeg";
import organDonationImprovementWhitePaper from "./images/organDonationImprovementWhitePaper.png";

import {
  FirstCol,
  FlexBox,
  ImageWrapper,
  PopoverContainer,
  SecondCol,
  SmallTitle,
  TextWrapper,
} from "./OrganProcurement.style";
import SolutionsNav from "../../../components/solutionsnav/SolutionsNav";
import PageTitle from "../../../components/pageTitle/PageTitle";
import SEO from "../../../components/seo/SEO";
import { Link } from "react-router-dom";
import { UnderLinedText } from "../../../components/underLinedText/UnderLinedText.style";
import PdfCard from "../../../components/pdfCard/PdfCard";
import { PdfCardContainer } from "../../../components/pdfCard/PdfCard.style";

const MyPopover = ({ top, left, children }) => {
  return (
    <>
      <PopoverContainer top={top} left={left}>
        <div className="x1">{children}</div>
        <div className="popover-arrow"></div>
      </PopoverContainer>
    </>
  );
};
const OrganProcurement = () => {
  const [hoverControl, setHoverControl] = useState(0);
  const colCount = 1;
  const pdcards = [
    {
      colCount,
      img: organDonationImprovementWhitePaper,
      url: "https://drive.google.com/file/d/1IK16PQ0XVhSLcEcvmVemXzrK0xUErHeF/view?usp=sharing",
    },
  ];
  return (
    <>
      <PageTitle
        title={"Digital Intern Educator Applications"}
        breadcrumb={[
          { url: "/solutions", desc: "Solutions" },
          {
            url: "/solutions/organ-procurement",
            desc: "iVMD for Organ Procurement",
          },
        ]}
      />
      <SEO
        title={"iVMD for Organ Procurement"}
        description={`The Digital Intern® includes a web interface for administrators to
    configure the application to fit the needs of their organization.
    The web interface also provides access to administrative reports and
    historical patient information.`}
      />
      
      <SolutionsNav />
      <TextWrapper className="container">
        <FlexBox className="my-5">
          <FirstCol>
            <UnderLinedText bottom={10} width={150}>
              <SmallTitle>Organ Procurement</SmallTitle>
            </UnderLinedText>
            
            The Digital Intern® is available for use by Organ Procurement
            Organizations in a beta format.{" "}
            <Link to="/contact">Contact us</Link> to get started today!
            <br />
            <br />
            The Digital Intern® iPad application enables OPCs to take the power
            of the Digital Intern® with them wherever they go. The iPad
            application requires patient data which can be obtained through
            manual data entry or an interface with your medical record software.
            <br />
            <br />
            The Digital Intern® includes a web interface for administrators to
            configure the application to fit the needs of their organization.
            The web interface also provides access to administrative reports and
            historical patient information.
            <br />
            <br />
            In addition to the Digital Intern® software, there is a
            comprehensive order set available to ensure all necessary labs,
            procedures, and medications have been ordered functioning as a
            comprehensive checklist. For DCD patients, pain and agitation
            management is included in the order set. Use of the order set with
            the Digital Intern® software ensures that everything the Digital
            Intern® recommends has been authorized by an order signed by the
            appropriate provider (OPC or physician, per your policies).
            <ImageWrapper>
              <img
                className="m-3"
                src={img}
                alt="Flowchart"
                useMap="#Map"
              ></img>
              {hoverControl == 1 && (
                <MyPopover top={98} left={255}>
                  The Digital Intern considers every change in the patient’s
                  condition and recommends the next steps to bring the patient’s
                  metrics in line with goals set by the physician. The Digital
                  Intern adapts its recommendations based on the patient’s
                  response to treatment, tailoring each recommendation to the
                  patient’s individual physiology. This approach is consistent
                  and thus will permit retrospective analysis of patient
                  populations for quality improvement initiatives.
                </MyPopover>
              )}
              {hoverControl == 5 && (
                <MyPopover top={-8} left={255}>
                  The physician determines when use of the Digital Intern is
                  appropriate and decides which aspects of patient care the
                  Digital Intern should help manage. The physician can exclude
                  certain medications from the Digital Intern’s toolbox or tell
                  the Digital Intern to use certain drugs only as a last resort.
                  The physician can set and change goals for patient metrics.
                </MyPopover>
              )}
              {hoverControl == 4 && (
                <MyPopover top={100} left={75}>
                  The EHR can supply data to the Digital Intern about the
                  patient. Nurses, doctors, and other caregivers will continue
                  to document in the EHR and should consider it the source of
                  truth for all patient data. The Digital Intern will interface
                  seamlessly with the EHR where possible so that workflow is
                  minimally altered thereby improving efficiency rather than
                  promoting discontinuity.
                </MyPopover>
              )}
              {hoverControl == 3 && (
                <MyPopover top={100} left={435}>
                  Hospital leadership can implement organization wide policies
                  to prioritize use of certain drugs or change the Digital
                  Intern’s default settings to help with cost containment. The
                  Digital Intern will make suggestions accordingly but will
                  abide by the physician’s treatment plan.
                </MyPopover>
              )}
              {hoverControl == 2 && (
                <MyPopover top={210} left={255}>
                  The care team works together with the Digital Intern as their
                  partner. Nursing and Respiratory Care staff can carry out the
                  recommendations of the Digital Intern without consulting a
                  physician for each decision, dose change, or lab result for
                  those things that the physician has ordered the Digital Intern
                  to manage.
                </MyPopover>
              )}
            </ImageWrapper>
            <map name="Map">
              <area
                alt="The physician determines when use of the Digital Intern is appropriate and decides which aspects of patient care the Digital Intern should help manage. The physician can exclude certain medications from the Digital Intern’s toolbox or tell the Digital Intern to use certain drugs only as a last resort. The physician can set and change goals for patient metrics."
                coords="181,4,335,81"
                shape="rect"
                href="#"
                onMouseEnter={() => setHoverControl(5)}
                onMouseLeave={() => setHoverControl(0)}
                rel="#"
                data-hasqtip="5"
                aria-describedby="qtip-0"
              />
              <area
                alt="The Digital Intern considers every change in the patient’s condition and recommends the next steps to bring the patient’s metrics in line with goals set by the physician. The Digital Intern adapts its recommendations based on the patient’s response to treatment, tailoring each recommendation to the patient’s individual physiology. This approach is consistent and thus will permit retrospective analysis of patient populations for quality improvement initiatives."
                coords="183,106,335,197"
                shape="rect"
                onMouseEnter={() => setHoverControl(1)}
                onMouseLeave={() => setHoverControl(0)}
                href="#"
                data-hasqtip="1"
                aria-describedby="qtip-1"
              />
              <area
                alt="The care team works together with the Digital Intern as their partner. Nursing and Respiratory Care staff can carry out the recommendations of the Digital Intern without consulting a physician for each decision, dose change, or lab result for those things that the physician has ordered the Digital Intern to manage."
                coords="186,225,334,304"
                shape="rect"
                href="#"
                onMouseEnter={() => setHoverControl(2)}
                onMouseLeave={() => setHoverControl(0)}
                data-hasqtip="2"
                aria-describedby="qtip-2"
              />
              <area
                alt="Hospital leadership can implement organization wide policies to prioritize use of certain drugs or change the Digital Intern’s default settings to help with cost containment. The Digital Intern will make suggestions accordingly but will abide by the physician’s treatment plan."
                coords="364,114,510,192"
                shape="rect"
                href="#"
                onMouseEnter={() => setHoverControl(3)}
                onMouseLeave={() => setHoverControl(0)}
                data-hasqtip="3"
                aria-describedby="qtip-3"
              />
              <area
                alt="The EMR can supply data to the Digital Intern about the patient. Nurses, doctors, and other caregivers will continue to document in the EMR and should consider it the source of truth for all patient data. The Digital Intern will interface seamlessly with the EMR where possible so that workflow is minimally altered thereby improving efficiency rather than promoting discontinuity."
                coords="4,114,154,191"
                shape="rect"
                href="#"
                onMouseEnter={() => setHoverControl(4)}
                onMouseLeave={() => setHoverControl(0)}
                data-hasqtip="4"
                aria-describedby="qtip-4"
              />{" "}
            </map>
          </FirstCol>

          <SecondCol>
          <UnderLinedText bottom={10} width={150}>
              <SmallTitle>Evaluation Methods</SmallTitle>
            </UnderLinedText>
            
            Our initial algorithms were developed specifically for organ donors
            and organ procurement organizations. The measurement for success was
            the number of organs recovered and the cost to recover them (from
            the standpoint of critical care time billed), compared between the
            control year and the treatment year when the Digital Intern® was
            operational.
            <UnderLinedText bottom={10} width={70}>
              <SmallTitle>Results</SmallTitle>
            </UnderLinedText>
            
            <div className="row">
              <div className="col-5">
                <img width={"100%"} src={result} alt="" />
              </div>
              <div className="col-7">
                The organs recovered per donor {"(excluding research)"} were
                2.83 in the control year and 3.54 in the treatment year. The
                results were statistically significant {"(p<0.0359)"}. In the
                control year the critical care hours billed averaged 5.9 per
                donor and 2.3 in the treatment year. These results are
                statistically significant {"(p<0.001)"}. and produced cost
                savings that averaged $2685 per donor.
              </div>
            </div>
            <UnderLinedText bottom={10} width={100}>
              <SmallTitle>Conclusions</SmallTitle>
            </UnderLinedText>
            
            The Digital Intern® is designed to manage complex patients with
            adaptive algorithms that respond to the patient’s physiology. It is
            fast, effective, and efficient. Future iterations are in development
            to extend care to other patient populations where defined metrics
            can be used to optimize medical management. In the meantime, the
            critical care physician has no worry of being sidelined and is
            certain that all of the patients are receiving appropriate levels of
            care reflecting the known best practices.
          </SecondCol>
        </FlexBox>
      </TextWrapper>
      
      <PdfCardContainer>
      {pdcards.map((item) => (
              <PdfCard key={Math.random()*10000} {...item} />
            ))}
      </PdfCardContainer>
    </>
  );
};

export default OrganProcurement;
